import {Action} from '@ngrx/store';
import {AirtimeBalanceModel} from '../pages/models/airtime.model';
import {KYCInfoModel} from '../pages/models/AirteltigoMoney';
import {PostPaidCategoryModel} from '../pages/models/PostPaid.model';

export const GET_BALANCE = 'GET_BALANCE';
export const SET_BALANCE = 'SET_BALANCE';
export const RESET_BALANCE = 'RESET_BALANCE';
export const GET_MFS_BALANCE = 'GET_MFS_BALANCE';
export const SET_MFS_BALANCE = 'SET_MFS_BALANCE';
export const GET_KYC = 'GET_KYC';
export const SET_KYC = 'SET_KYC';
export const GET_CREDIT_LIMIT = 'GET_CREDIT_LIMIT';
export const SET_CREDIT_LIMIT = 'SET_CREDIT_LIMIT';

export class AccountBalanceGetAction implements Action {
  readonly type = GET_BALANCE;

  constructor() {

  }
}

export class AccountBalanceSetAction implements Action {
  readonly type = SET_BALANCE;

  constructor(public payload: { balanceInfo: AirtimeBalanceModel }) {

  }
}

export class ResetAccountBalanceAction {
  readonly type = RESET_BALANCE;

  constructor() {

  }
}

export class GetMFSBalanceAction implements Action {
  readonly type = GET_MFS_BALANCE;

  public constructor() {

  }
}

export class SetMFSBalanceAction implements Action {
  readonly type = SET_MFS_BALANCE;

  constructor(public payload: string) {

  }
}

export class GetKYC implements Action {
  readonly type = GET_KYC;

  constructor() {

  }
}

export class SetKYC implements Action {
  readonly type = SET_KYC;

  constructor(public payload: { kycInfo: KYCInfoModel }) {

  }
}

export class GetPostPaidCreditLimit implements Action {
  readonly type = GET_CREDIT_LIMIT;

  constructor() {

  }
}

export class SetPostPaidBalance implements Action {
  readonly type = SET_CREDIT_LIMIT;

  constructor(public payload: { creditLimit: number }) {

  }
}

export type AccountBalanceAction =
  AccountBalanceGetAction
  | AccountBalanceSetAction
  | ResetAccountBalanceAction
  | GetMFSBalanceAction
  | SetMFSBalanceAction
  | GetPostPaidCreditLimit
  | SetPostPaidBalance;
