import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {HelpService} from '../../../services/help.service';

@Component({
  selector: 'app-suceess-page',
  templateUrl: './suceess-page.component.html',
  styleUrls: ['./suceess-page.component.scss']
})
export class SuceessPageComponent implements OnInit, OnDestroy {
  public isAuthenticated: boolean = false;      // contains state of authentication
  public successData = {
    heading: 'Testing video',
    body1: 'Amazing animated video',
    body2: null,
    route: null,
    shouldRoute: undefined,
    videoPath: '',
    isAuthenticated: false
  };

  constructor(private router: Router, private help: HelpService) {
    this.isAuthenticated = sessionStorage.getItem('token') !== null;
    if (sessionStorage.getItem('success-result')) {
      this.successData = JSON.parse(sessionStorage.getItem('success-result'));
    }
  }

  ngOnInit() {
  }

  public goToHome(): void {
    this.router.navigate(['airteltigo/home']);
  }

  ngOnDestroy(): void {
    this.help.emitCurrentStepData(null);
    sessionStorage.removeItem('success-result');
  }

}
