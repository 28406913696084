import {Component, OnInit} from '@angular/core';
import {CrudService} from '../../services/crud.service';
import {HelpService} from '../../services/help.service';
import * as fromReducer from '../../app-store/account-balance.reducer';
import * as AccountBalanceActions from '../../app-store/account-balance.action';
import {Store} from '@ngrx/store';
import {SocketService} from '../../services/socket.service';
import {UserInfoModel} from 'src/app/pages/models/user.model';
import {Router} from '@angular/router';

@Component({
  selector: 'app-authorized-layout',
  templateUrl: './authorized-layout.component.html',
  styleUrls: ['./authorized-layout.component.scss']
})
export class AuthorizedLayoutComponent implements OnInit {
  private simType: number; // contains user's sim type
  public userInfo: UserInfoModel; // contains information about logged in user
  constructor(
    private crud: CrudService,
    private helperService: HelpService,
    private store: Store<fromReducer.AccountBalanceState>,
    private router: Router,
    private socket: SocketService
  ) {
    this.simType = JSON.parse(atob(sessionStorage.getItem('userData')))[
      'simType'
      ];

    this.getUserBalanceInfo();
    this.getBannerImages();
  }

  // gets user's and balance information
  public getUserBalanceInfo() {
    if (sessionStorage.getItem('token') && sessionStorage.getItem('id')) {
      this.getAirtimeBalance();
      if (this.simType === 1) {
        this.getPostPaidCreditBalance();
      }
      this.getUserInformation();
      // this.getUserMFSInformation();
      this.getRBTRegistrationStatus();
      this.getKYCInformation();
    }
  }

  // dispatches action to get postpaid balance
  private getPostPaidCreditBalance(): void {
    this.store.dispatch(new AccountBalanceActions.GetPostPaidCreditLimit());
  }

  // get's users MFS balance information
  private getUserMFSInformation() {
    this.store.dispatch(new AccountBalanceActions.GetMFSBalanceAction());
  }

  // gets banner images
  private getBannerImages(): void {
    if (this.simType === undefined || this.simType === null) {
      const simType = JSON.parse(atob(sessionStorage.getItem('userData')))[
        'simType'
        ];
      this.simType = simType === 'Prepaid' ? 0 : 1;
    }
    if (typeof this.simType === 'string') {
      this.simType = this.simType === 'Prepaid' ? 0 : 1;
    }
    // sessionStorage.setItem('simType', String(this.simType));
    this.crud.getData(`banner/${this.simType}`, 'auth').subscribe(
      (res: any) => {
        if (res.response_code === 200) {
          this.helperService.emitBannerImages(res.response_data.data);
        }
      },
      error => {
        this.helperService.emitBannerImages(null);
      });
  }

  // gets RBT subscription status
  private getRBTRegistrationStatus(): void {
    this.crud.getData('rbt/check/registered/user', 'mfs').subscribe(
      (res: any) => {
        if (res.response_code !== 200) {
          sessionStorage.setItem('RBT-status', 'false');
        }
        const status = (res.response_data.code === '0') ? 1 : 0;
        sessionStorage.setItem('RBT-status', status.toString());
        this.helperService.emitRBTStatus(true);
      },
      error => {
        sessionStorage.setItem('RBT-status', 'false');
      }
    );
  }

  // gets user's information
  private getUserInformation(): void {
    this.crud.getData('user/me', 'auth').subscribe((res: any) => {
      if (res.response_code === 200) {
        const encodedData = btoa(JSON.stringify(res.response_data.data));
        sessionStorage.setItem('userData', encodedData);
      }
    });
  }

  // emits user's information
  private intilizeChat(): void {
    this.userInfo = JSON.parse(atob(sessionStorage.getItem('userData')));
    const name = this.userInfo.customerDetails.firstName;
    const chatData = {
      sender: this.userInfo._id,
      senderName: name !== undefined && ' ' ? name : 'AirtelTigo'
    };
    this.socket.emitUserInfo(chatData);
    this.router.navigate(['airteltigo/chat']);
  }

  // check- is chat exist or not ? //
  public ischatExist(): void {
    this.crud.getSocketData('chats').subscribe(
      (res: any) => {
        const response = res.response_code === 200 ? res.response_data.data : Object.assign({data: null});
        if (!response) {
          this.intilizeChat();
        } else {
          this.router.navigate(['airteltigo/chat']);
        }
      },
      error => {
        console.log('data', error);
      });
  }

  // gets user's airtime balance information
  private getAirtimeBalance(): void {
    this.store.dispatch(new AccountBalanceActions.AccountBalanceGetAction());
  }

  // get's users kyc information
  private getKYCInformation(): void {
    this.store.dispatch(new AccountBalanceActions.GetKYC());
  }

  ngOnInit() {
    this.socket.chatData(sessionStorage.getItem('id'));
    this.socket.emiUserCurrentActiveChat(sessionStorage.getItem('id'));
    this.socket.listenToChatMessage(sessionStorage.getItem('id'));
    this.socket.chatCloseEvent();
    this.socket.listenTOCurrentActiveChat(sessionStorage.getItem('id'));
    setInterval(() => {
      this.getUserBalanceInfo();
    }, 180000);
  }
}
