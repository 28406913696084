import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material';
import {MatSelectModule} from '@angular/material';
import {MatMenuModule} from '@angular/material/menu';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './layout/header/header.component';
import {FooterComponent} from './layout/footer/footer.component';
import {PreloginSidebarComponent} from './layout/prelogin-sidebar/prelogin-sidebar.component';
import {SidebarComponent} from './layout/sidebar/sidebar.component';
import {SidepanelComponent} from './layout/sidepanel/sidepanel.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AuthorizedLayoutComponent} from './layout/authorized-layout/authorized-layout.component';
import {UnAuthorizedLayoutComponent} from './layout/un-authorized-layout/un-authorized-layout.component';
import {CommonComponentsModule} from './pages/common-components/common.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CrudInterceptorService} from './services/crud-interceptor.service';

// owl carousel
import {OwlModule} from 'ngx-owl-carousel';
import {StoreModule} from '@ngrx/store';
import {accountBalanceReducer} from './app-store/account-balance.reducer';
import {EffectsModule} from '@ngrx/effects';
import {AccountBalanceEffects} from './app-store/account-balance-effects';
import {SocketService} from './services/socket.service';
import {HttpModule} from '@angular/http';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    SidepanelComponent,
    PreloginSidebarComponent,
    AuthorizedLayoutComponent,
    UnAuthorizedLayoutComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatTabsModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatMenuModule,
    CommonComponentsModule,
    HttpClientModule,
    OwlModule,
    StoreModule.forRoot({balanceInfo: accountBalanceReducer}),
    StoreDevtoolsModule.instrument({
      maxAge: 30
    }),
    EffectsModule.forRoot([AccountBalanceEffects]),
    HttpModule
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyAiHZR2ffRqMVQX0CzKFN1Hl1H134YZPcs'
    // })
  ],
  providers: [{provide: HTTP_INTERCEPTORS, useClass: CrudInterceptorService, multi: true}, SocketService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
