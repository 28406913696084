import {Component, OnInit} from '@angular/core';
import {CrudService} from '../../services/crud.service';
import {Router} from '@angular/router';
import {HelpService} from '../../services/help.service';
import {Store} from '@ngrx/store';
import {BalanceInfoState} from '../../app-store/account-balance.reducer';
import * as AccountBalanceAction from '../../app-store/account-balance.action';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public selectedTab: string = 'home';      // contains default selected tab
  public currentYear: number = 0;     // contains current active year

  constructor(private crud: CrudService, private router: Router, private helpService: HelpService, private store: Store<BalanceInfoState>) {
    this.currentYear = new Date().getFullYear();
    this.selectedTab = sessionStorage.getItem('path') ? sessionStorage.getItem('path') : 'home';
  }

  ngOnInit() {
  }

  // log's out the user
  public logout(): void {
    this.crud.setToken = null;
    sessionStorage.clear();
    this.store.dispatch(new AccountBalanceAction.ResetAccountBalanceAction());
    this.router.navigate(['auth/home']);
  }

  // sets's selected route
  public setSelectedPath(): void {
    sessionStorage.setItem('path', this.selectedTab);
  }

  // show's alert window
  public showAlertWindow(): void {
    alert('Coming soon');
  }

}
