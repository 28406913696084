import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {CrudService} from '../../../services/crud.service';
import {MapViewModel, ShopsModel} from '../../models/auth-models.model';
import {ActivatedRoute} from '@angular/router';
import {PremierBenefitModel} from '../../models/premier-benefit.model';
import {Http} from '@angular/http';
import {catchError, map} from 'rxjs/operators';
import {EMPTY} from 'rxjs';
import {MFSAgentModel} from '../../models/user.model';

@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.scss']
})
export class MapViewComponent implements OnInit {
  public latitude: number = null;             // latitude of ghana
  public longitude: number = null;            // longitude of ghana
  public shops: Array<ShopsModel> = [];         // contains list of all shops
  public selectedShop: ShopsModel;              // contains information about selected shop
  @Output() shopInfo = new EventEmitter<ShopsModel>();          // emits selected shop after user selects a shop
  @Output() closeMapView = new EventEmitter<boolean>();           // emits true when shop is selected
  public iconURL: string = 'assets/img/icons/pointer.svg';             // contains marker icon url
  @Input() isView: boolean = false;                  // set to true if user wants to see the shop locations
  public searchInput: string = '';                    // searches shops based on name
  public premierPartners: Array<PremierBenefitModel> = [];        // contains list of premier partners
  public shopAndPartnerLists: Array<MapViewModel> = [];           // contains list of shops and partners
  public shopAndPartnerListsCopy: Array<MapViewModel> = [];           // contains list of shops and partners
  public filterValue: string = 'all';           // contains default filter value
  public userLocation: MapViewModel = {
    name: 'User',
    address: '',
    openTime: null,
    closeTime: null,
    logo: 'assets/img/svg-icon/ico_mylocation.svg',
    lng: null,
    lat: null,
    _id: null,
    type: 'user',
    mobileNumber: null
  };
  @ViewChild('infoWindow') public infoWindow;       // contains selected marker info window

  constructor(private crud: CrudService, private http: Http) {

  }

  ngOnInit() {
    this.getCurrentPosition();
  }

  // get's user current position
  private getCurrentPosition(): void {
    navigator.geolocation.getCurrentPosition((position) => {
      [this.userLocation.lat, this.userLocation.lng] = [position.coords.latitude, position.coords.longitude];
      this.latitude = this.userLocation.lat;
      this.longitude = this.userLocation.lng;
      this.getLocationInformation(this.userLocation.lat, this.userLocation.lng);
    }, error => {
      console.log('Location cancelled');
      this.latitude = 5.5552;
      this.longitude = -0.2048;
      this.userLocation.lat = 5.5552;
      this.userLocation.lng = -0.2048;
      this.userLocation.address = 'Barnes road, opposite the National Service Secretariat';
      this.getShopsList();
    });
  }

  // get's location information
  public getLocationInformation(latitude: number, longitude: number): void {
    this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyD6Q4UgAYOL203nuwNeBr4j_-yAd1U1gko`).pipe(
      map(res => res.json()),
      catchError(error => EMPTY)
    ).subscribe((data) => {
      const subLocalityInfo = data.results.find(list => list.types.includes('sublocality'));
      if (subLocalityInfo) {
        this.userLocation.address = subLocalityInfo.formatted_address;
        this.getShopsList();
      }
    });
  }

  // get's MFS agents list
  private getMFSAgentList(): void {
    this.crud.getData('agent/list', 'user').subscribe((res: any) => {
      if (res.response_code !== 200) {
        return;
      }
      res.response_data.data.forEach((agent: MFSAgentModel) => {
        const body: MapViewModel = {
          name: agent.shopLocation,
          address: agent.address,
          openTime: null,
          closeTime: null,
          logo: 'assets/img/svg-icon/Mfs-agent.svg',
          lng: agent.latitude,
          lat: agent.logitude,
          _id: agent._id,
          mobileNumber: agent.mobileNumber,
          type: 'agent'
        };
        this.shopAndPartnerLists.push(body);
      });
      this.shopAndPartnerListsCopy = this.shopAndPartnerLists;
    }, error => {

    });
  }

  // get's list of premier partners
  private getPremierPartners(): void {
    this.crud.getData('premiumbenifitpartner', 'auth').subscribe((res: any) => {
      if (res.response_code === 200) {
        res.response_data.data.forEach((data: PremierBenefitModel) => {
          const body: MapViewModel = {
            name: data.benefitPartner,
            address: data.address,
            openTime: this.getOpenTime(data),
            closeTime: this.getCloseTime(data),
            logo: 'assets/img/svg-icon/ppartner.svg',
            lng: data.longitude,
            lat: data.latitude,
            _id: data._id,
            type: 'partner',
            mobileNumber: null
          };
          this.shopAndPartnerLists.push(body);
        });
        this.shopAndPartnerListsCopy = this.shopAndPartnerLists;
        if (this.isView) {
          this.getMFSAgentList();
        }
      }
    }, error => {
      this.premierPartners = [];
      this.shopAndPartnerListsCopy = [];
    });
  }

  // checks todays date and returns open time
  private getOpenTime = (partner: PremierBenefitModel) => (new Date().getDay() > 0 && new Date().getDay() <= 5) ? this.formatWorkingHours(partner.weekDaysWorkingHrs, 0) : this.formatWorkingHours(partner.weekendWorkingHrs, 0);

  // checks todays date and returns close time
  private getCloseTime = (partner: PremierBenefitModel) => (new Date().getDay() > 0 && new Date().getDay() <= 5) ? this.formatWorkingHours(partner.weekDaysWorkingHrs, 1) : this.formatWorkingHours(partner.weekendWorkingHrs, 1);

  private formatWorkingHours = (date: string, index: number) => date.split('-')[index];

  // gets list of all shops
  private getShopsList(): void {
    this.crud.getData('vendor', 'auth').subscribe((res: any) => {
      if (res.response_code === 200) {
        res.response_data.forEach((shop: ShopsModel) => {
          shop.todaysWorkTimings = shop.workingHrs.find(work => work.daycode === (new Date().getDay() !== 0 ? new Date().getDay() - 1 : new Date().getDay()));
          let closingTime = shop.todaysWorkTimings.closeTime.split(':');
          if (Number(closingTime[0]) > 12) {
            closingTime[0] = (Number(closingTime[0]) - 12).toString();
            shop.todaysWorkTimings.closeTime = closingTime.join(':');
          }
          const body: MapViewModel = {
            name: shop.shopName,
            address: shop.locationInfo,
            openTime: shop.todaysWorkTimings.openTime + 'am',
            closeTime: shop.todaysWorkTimings.closeTime + 'pm',
            logo: 'assets/img/svg-icon/ashop.svg',
            lat: shop.longLat.coordinates[1],
            lng: shop.longLat.coordinates[0],
            _id: shop._id,
            type: 'shop',
            mobileNumber: null
          };
          this.shopAndPartnerLists.push(body);
        });
        this.shopAndPartnerLists.push(this.userLocation);
        if (this.isView) {
          this.getPremierPartners();
        }
        this.shopAndPartnerListsCopy = this.shopAndPartnerLists;
      }
      this.shops = res.response_code === 200 ? res.response_data : [];
    }, error => {
      this.shops = [];
      this.shopAndPartnerListsCopy = [];
    });
  }

  // get selected shops information
  public selectShop(shop: MapViewModel, infoWindow) {
    const shopData = this.shops.find(data => data._id === shop._id);
    this.selectedShop = shopData;
    if (this.infoWindow !== infoWindow) {
      this.infoWindow.close();
    }
    this.infoWindow = infoWindow;
    this.selectedShop = shopData;
    if (!this.isView) {
      if (confirm('Are you sure you want to select this shop')) {
        if (shopData) {
          this.shopInfo.next(this.selectedShop);
        }
      }
    }
  }

  // filters list based on shop, partners or both
  public filterList(): void {
    if (this.filterValue === 'all') {
      this.shopAndPartnerLists = this.shopAndPartnerListsCopy;
      return;
    }
    this.shopAndPartnerLists = this.shopAndPartnerListsCopy.filter(list => list.type === this.filterValue);
    this.shopAndPartnerLists.push(this.userLocation);
  }

  // filters shop/partners by name
  public searchShop(): void {
    if (this.searchInput.length > 0) {
      this.shopAndPartnerLists = this.shopAndPartnerListsCopy.filter(shop => shop.name.toLowerCase().indexOf(this.searchInput.toLowerCase()) !== -1);
    } else {
      this.shopAndPartnerLists = this.shopAndPartnerListsCopy;
    }
  }

  // sets selected shop
  public setSelectedShop() {
    this.closeMapView.next(true);
  }

}
