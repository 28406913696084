import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CrudService {
  private AUTH_TOKEN: string = null;          // contains user's authentication token

  constructor(private http: HttpClient) {

  }

  // modifies crud end point url
  private endPointUrl(url: string, type: string): string {
    return (type === 'mfs' ? environment.MFS_RBT_URL + url : (type === 'user' ? environment.USER_URL + url : environment.AUTH_URL + url));
  }

  // modifies socket endpoint url
  private socketPointUrl(url: string): string {
    return environment.SOCKET_URL + url;
  }

  // sets user's authentication token
  public set setToken(token: string) {
    this.AUTH_TOKEN = token;
  }

  // returns user's authentication token
  public get getToken(): string {
    return this.AUTH_TOKEN;
  }

  // FIXME:// contains all api related methods

  // gets information from server
  public getData(url: string, type: string): Observable<any> {
    return this.http.get(this.endPointUrl(url, type));
  }

  // gets information from server
  public getSocketData(url: string): Observable<any> {
    return this.http.get(this.socketPointUrl(url));
  }

  // sends request to create a new record
  public saveData(url: string, data: any, type: string): Observable<any> {
    return this.http.post(this.endPointUrl(url, type), data);
  }

  // sends request to update record
  public updateRecord(url: string, data: any, type: string): Observable<any> {
    return this.http.put(this.endPointUrl(url, type), data);
  }

  // sends request to delete record
  public deleteRecord(url: string, type: string) {
    return this.http.delete(this.endPointUrl(url, type));
  }
}
