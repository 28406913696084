import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {ChangePinModel, TransactionResponseModel} from '../../models/AirteltigoMoney';
import {CrudService} from '../../../services/crud.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {HelpService} from '../../../services/help.service';

@Component({
  selector: 'app-change-pin',
  templateUrl: './change-pin.component.html',
  styleUrls: ['./change-pin.component.scss']
})
export class ChangePinComponent implements OnInit, OnChanges {
  public otpData: ChangePinModel;     // contains information to change pin
  public oldPinFirstVal: string = '';     // contains old pins first val
  public oldPinSecondVal: string = '';     // contains old pins second val
  public oldPinThirdVal: string = '';     // contains old pins third val
  public oldPinFourthVal: string = '';     // contains old pins fourth val
  public oldPinFifthVal: string = '';     // contains old pins fourth val

  public newPinFirstVal: string = '';     // contains new pins first val
  public newPinSecondVal: string = '';     // contains new pins second val
  public newPinThirdVal: string = '';     // contains new pins third val
  public newPinFourthVal: string = '';     // contains new pins fourth val

  public confPinFirstVal: string = '';     // contains new pins first val
  public confPinSecondVal: string = '';     // contains new pins second val
  public confPinThirdVal: string = '';     // contains new pins third val
  public confPinFourthVal: string = '';     // contains new pins fourth val
  @Input() public changeType: string = null;     // contains type of pin change
  @Input() public maxInpBox: number;      // contains maximum number of input box
  @ViewChild('content') public content: TemplateRef<any>;     // contains reference to change pin modal
  public modalRef: NgbModalRef;     // contains reference to change pin modal
  @Output() public modalClosed = new EventEmitter<boolean>();     // emits true when user closes the modal
  @Output() public transactionStatus = new EventEmitter<TransactionResponseModel>();     // emits transaction error message
  private defaultErrorRes = {
    response_code: 400,
    response_data: {
      message: 'Something went wrong, Please try again'
    }
  };        // contains default error response
  public isLoading: boolean = false;    // shows loader when true
  private isPINReset: boolean = false;      // set to true when user has reset the pin

  constructor(private crud: CrudService, private modalService: NgbModal, private help: HelpService) {
    this.otpData = this.getDefaultOTPData();
  }

  ngOnInit() {
    this.isPINReset = sessionStorage.getItem('resetPIN') !== null;
  }

  ngOnChanges(): void {
    setTimeout(() => {
      this.openModal();
    }, 1000);
  }

  // open change pin modal
  private openModal(): void {
    this.modalRef = this.modalService.open(this.content, {ariaLabelledBy: 'modal-basic-title', backdrop: false, keyboard: false});
  }

  // get default otp data
  private getDefaultOTPData(): ChangePinModel {
    return {
      oldPin: '',
      newPin: '',
      confirmPin: '',
      channel: 'Web App'
    };
  }

  // appends otp entered by user
  public otpVal(event, value: string): void {
    this.otpData.oldPin = this.oldPinFirstVal + this.oldPinSecondVal + this.oldPinThirdVal + this.oldPinFourthVal + this.oldPinFifthVal;
    if (event.inputType !== 'deleteContentBackward') {
      switch (this.otpData.oldPin.length) {
        case 0:
          const id1 = document.getElementById('otp1');
          id1.focus();
          break;
        case 1:
          const id2 = document.getElementById('otp2');
          id2.focus();
          break;
        case 2:
          const id3 = document.getElementById('otp3');
          id3.focus();
          break;
        case 3:
          const id4 = document.getElementById('otp4');
          id4.focus();
          break;
        case 4:
          const id5 = document.getElementById('5Pin');
          if (id5) {
            id5.focus();
          }
          break;
      }
    }
  }

  // appends otp entered by user
  public newOTPVal(event, value: string): void {
    this.otpData.newPin = this.newPinFirstVal + this.newPinSecondVal + this.newPinThirdVal + this.newPinFourthVal;
    if (event.inputType !== 'deleteContentBackward') {
      switch (this.otpData.newPin.length) {
        case 0:
          const id1 = document.getElementById('otp5');
          id1.focus();
          break;
        case 1:
          const id2 = document.getElementById('otp6');
          id2.focus();
          break;
        case 2:
          const id3 = document.getElementById('otp7');
          id3.focus();
          break;
        case 3:
          const id4 = document.getElementById('otp8');
          id4.focus();
          break;
      }
    }
  }

  // appends otp entered by user
  public confirmOTPVal(event, value: string): void {
    this.otpData.confirmPin = this.confPinFirstVal + this.confPinSecondVal + this.confPinThirdVal + this.confPinFourthVal;
    if (event.inputType !== 'deleteContentBackward') {
      switch (this.otpData.confirmPin.length) {
        case 0:
          const id1 = document.getElementById('otp9');
          id1.focus();
          break;
        case 1:
          const id2 = document.getElementById('otp10');
          id2.focus();
          break;
        case 2:
          const id3 = document.getElementById('otp11');
          id3.focus();
          break;
        case 3:
          const id4 = document.getElementById('otp12');
          id4.focus();
          break;
      }
    }
  }

  // sends request to change pin
  public changePIN(): void {
    this.otpData.confirmPin = this.otpData.newPin;
    const otpData: ChangePinModel = {
      oldPin: this.help.encodeMfsData(this.otpData.oldPin),
      newPin: this.help.encodeMfsData(this.otpData.newPin),
      confirmPin: this.help.encodeMfsData(this.otpData.confirmPin),
      channel: this.otpData.channel
    };
    this.isLoading = true;
    this.crud.saveData('mfs/change/pin', otpData, 'mfs').subscribe((res: any) => {
      if (this.isPINReset) {
        sessionStorage.removeItem('resetPIN');
      }
      this.isPINReset = false;
      this.isLoading = false;
      this.modalRef.close();
      setTimeout(() => {
        this.handleTransactionResponse(res);
      }, 1000);
    }, error => {
      this.handleTransactionResponse(this.defaultErrorRes);
    });
  }

  // handles transaction response
  private handleTransactionResponse(res): void {
    if (res.response_code !== 200) {
      const errorBody: TransactionResponseModel = {
        status: false,
        message: res.response_data.message
      };
      this.transactionStatus.next(errorBody);
      return;
    }
    const successBody: TransactionResponseModel = {
      status: true,
      message: res.response_data.message
    };
    this.transactionStatus.next(successBody);
  }

  // closes modal
  public closeModal(): void {
    if (this.isPINReset) {
      return alert('You have reset your PIN. You have to change your PIN before performing any other transaction');
    }
    this.modalRef.close();
    setTimeout(() => {
      this.modalClosed.next(true);
    }, 700);
  }

}
