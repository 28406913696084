import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import * as io from 'socket.io-client';
import {BehaviorSubject} from 'rxjs';
import {ChatMessageModel} from '../pages/models/chat.model';

@Injectable()
export class SocketService {
  private socket: SocketIOClient.Socket;
  private chatApprovalSubject = new BehaviorSubject(null);
  public chatReqData = this.chatApprovalSubject.asObservable();

  private chatCloseSubject = new BehaviorSubject(null);
  public isChatClose = this.chatCloseSubject.asObservable();

  private messageData = new BehaviorSubject<ChatMessageModel>(null); // emits new message
  public messageInfo = this.messageData.asObservable(); // subscribe to message data subject, subscribes last value emitted
  constructor() {
  }

  // establishes socket connection with server
  public connect(): void {
    this.socket = io(environment.SOCKET_URL);
    this.socket.on('connect', () => {
      console.log('socket connected');
      this.socket.emit('room', environment.chatRoom);
    });
    this.socket.on('connect_error', () => {
      console.clear();
    });
    this.socket.on('disconnect', () => {
      console.log('socket disconnected');
    });
  }

  // emits user info to initialize chat
  public emitUserInfo(data: any): void {
    this.socket.emit('chat', data);
  }

  // get chat data
  public chatData(userId: string) {
    this.socket.on('listenStatus' + userId, data => {
      this.chatApprovalSubject.next(data);
    });
  }

  // emits user id to get current active chat
  public emiUserCurrentActiveChat(id: string): void {
    this.socket.emit('currentUserChat', {id: id});
  }

  // listens to current active chat
  public listenTOCurrentActiveChat(id: string): void {
    this.socket.on('listenCurrentUserChat' + id, data => {
      this.chatApprovalSubject.next(data);
    });
  }

  // emits chat message
  public emitChatMessage(message: ChatMessageModel): void {
    this.socket.emit('chatRegularEvent', message);
  }

  // listens to chat messages
  public listenToChatMessage(id: string): void {
    this.socket.on('listenMessageStatus' + id, data => {
      this.messageData.next(data.data);
    });
  }

  // reset message subject
  public resetMessageSubjectValue(): void {
    this.messageData.next(null);
  }

  // emits event to close chat
  public closeChat(body): void {
    this.socket.emit('chatClosedEvent', body);
  }

  // closes chat event
  public chatCloseEvent(): void {
    this.socket.on('listenChatClosed', listen => {
      this.chatCloseSubject.next(listen);
    });
  }

  // disconnect socket
  public disconnectSocket() {
    // this.socket.disconnect();
    this.chatCloseSubject.next(null);
    this.chatApprovalSubject.next(null);
  }

}
