import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {MapViewComponent} from './map-view/map-view.component';
import {OtpVeificationComponent} from './otp-veification/otp-veification.component';
import {SuceessPageComponent} from './suceess-page/suceess-page.component';
import {PaymentConfirmationComponent} from './payment-confirmation/payment-confirmation.component';
import {RouterModule, Routes} from '@angular/router';
import {AgmCoreModule} from '@agm/core';
import {NgbDatepickerModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NumberOnlyDirective} from '../../directives/number-only.directive';
import {CountdownModule} from 'ngx-countdown';
import {MatFormFieldModule, MatSelectModule} from '@angular/material';
import {FileUploadModule} from 'ng2-file-upload';
import {MfaPaymentConfirmationComponent} from './mfs-payment-confirmation/mfa-payment-confirmation.component';
import {ErrorComponent} from './error-page/error.component';
import {ChangePinComponent} from './change-pin/change-pin.component';
import {HelpService} from '../../services/help.service';

const routes: Routes = [
  {path: 'map', component: MapViewComponent},
  {path: 'success', component: SuceessPageComponent},
  {path: 'payment', component: PaymentConfirmationComponent},
  {path: 'error-page', component: ErrorComponent}
];

@NgModule({
  imports: [FormsModule, CommonModule, RouterModule.forChild(routes), FileUploadModule, AgmCoreModule.forRoot({
    apiKey: 'AIzaSyD6Q4UgAYOL203nuwNeBr4j_-yAd1U1gko'
  }), NgbModule, CountdownModule, MatFormFieldModule, MatSelectModule, NgbDatepickerModule],
  declarations: [MapViewComponent, OtpVeificationComponent, SuceessPageComponent, PaymentConfirmationComponent, NumberOnlyDirective, MfaPaymentConfirmationComponent, ErrorComponent, ChangePinComponent],
  exports: [PaymentConfirmationComponent, MapViewComponent, OtpVeificationComponent, SuceessPageComponent, RouterModule, AgmCoreModule, NumberOnlyDirective, MfaPaymentConfirmationComponent, ErrorComponent, ChangePinComponent, MatSelectModule]
})
export class CommonComponentsModule {

}
