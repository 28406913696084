import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthorizedLayoutComponent} from './layout/authorized-layout/authorized-layout.component';
import {UnAuthorizedLayoutComponent} from './layout/un-authorized-layout/un-authorized-layout.component';
import {AuthGuardService} from './services/auth-guard.service';

const routes: Routes = [
  {path: '', redirectTo: 'auth/home', pathMatch: 'full'},
  {
    path: '', component: AuthorizedLayoutComponent, canActivate: [AuthGuardService], children: [
      {
        path: 'airteltigo',
        canActivateChild: [AuthGuardService],
        loadChildren: './pages/authorized-module/authorized.module#AuthorizedModule'
      }
    ]
  },
  {
    path: '', component: UnAuthorizedLayoutComponent, children: [
      {path: 'auth', loadChildren: './pages/unauthorized-module/unauthorized.module#UnAuthorizedModule'}
    ]
  },
  {
    path: '**',
    redirectTo: 'airteltigo'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
