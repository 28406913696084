import {Component, OnInit} from '@angular/core';
import {HelpService} from '../../services/help.service';
import {ProcessModel} from '../../pages/models/auth-models.model';

@Component({
  selector: 'app-prelogin-sidebar',
  templateUrl: './prelogin-sidebar.component.html',
  styleUrls: ['./prelogin-sidebar.component.scss']
})
export class PreloginSidebarComponent implements OnInit {
  public hideFirstTip: boolean = false;         // first first time tip
  public processLists: Array<ProcessModel> = [];      // contains all process/steps involved in buying a new sim

  constructor(private helpService: HelpService) {
    this.helpService.hideFirstTip(true);
  }

  ngOnInit() {
    this.helpService.firstTip.subscribe(state => {
      this.hideFirstTip = state;
    });

    this.helpService.buyProcessData.subscribe(data => {
      if (data) {
        const index = this.processLists.findIndex(list => list.type === data.type);
        if (index !== -1) {
          this.processLists.splice(index, 1, data);
        } else {
          this.processLists.push(data);
        }
      } else {
        this.processLists = [];
      }
    });
  }

}
