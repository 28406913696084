import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {NgbModal, ModalDismissReasons, NgbModalRef, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {CrudService} from '../../../services/crud.service';
import {
  BrowserDetailsModel,
  CustomerDetailsModel,
  IdTypesModel,
  UserFormModel,
  UserInfoModel
} from '../../models/user.model';
import {Router} from '@angular/router';
import {FileUploader} from 'ng2-file-upload';
import {environment} from '../../../../environments/environment';
import {HelpService} from '../../../services/help.service';

@Component({
  selector: 'app-otp-veification',
  templateUrl: './otp-veification.component.html',
  styleUrls: ['./otp-veification.component.scss']
})
export class OtpVeificationComponent implements OnInit, OnChanges {
  @Input() showModal: boolean = false;        // listens to an event from parent component
  @Input() otpId: string = null;            // contains otp authentication id
  @Output() otpVerified = new EventEmitter<boolean>();        // emits event when otp is successfully verified
  @ViewChild('content') content: TemplateRef<NgbModal>;     // contains otp modal reference
  public modalRef: NgbModalRef;         // contains otp modal reference
  public firstVal: string = '';
  public secondVal: string = '';
  public thirdVal: string = '';
  public fourthVal: string = '';
  public numberVerifyCode: string = '';              // contains otp of the user
  closeResult: string;
  public isError: boolean = false;                  // set to true when user entered a wrong otp
  public isOTPVerified: boolean = false;            // set to true when otp is verified
  @Input() forAuthentication: boolean = false;      // shows authentication section when true
  public isLoading: boolean = false;              // disables submit button when true
  public testDuration: number = 5;            // contains duration to resend OTP
  public enableResend: boolean = false;       // enables resend button when timer is completed
  @Input() userNumber: string = null;           // contains user's mobile number
  @Input() fromLoginPage: boolean = false;      // set to true if otp verification page is opened from login page
  @Output() pinVerified = new EventEmitter<string>();       // emits and event when otp is verified
  private deviceDetail: BrowserDetailsModel;      // contains details of browser in which this application is executed.
  public idTypes: Array<IdTypesModel> = [
    {idName: 'NIA (National Id)', idVal: 'NIA', pattern: '/^[a-zA-Z0-9 "!?.-]+$/', min: '13', max: '15'},
    {idName: 'DVLA (Driving License)', idVal: 'DVLA', pattern: '/^[a-zA-Z0-9 "!?.-]+$/', min: '14', max: '17'},
    {idName: 'NHIS (National Health ID)', idVal: 'NHIS', pattern: '/^[a-zA-Z0-9 "!?.-]+$/', min: '8', max: '8'},
    {idName: 'EC (Electoral Card )', idVal: 'Voters ID', pattern: '[0-9]+', min: '10', max: '10'},
    {idName: 'Passport', idVal: 'NIA', pattern: '/^[a-zA-Z0-9 "!?.-]+$/', min: '10', max: '15'}
  ];                  // contains all the id types
  public customerDetails: UserFormModel;        // contains user's KYC information
  public isDocImageSelected: boolean = false;     // set to true when user selects a document
  public uploader = new FileUploader({
    url: environment.AUTH_URL + 'uploadfile'
  });       // contains uploader configuration
  @Input() fromHomePage: boolean = false;       // set to true if authentication is requested from home page

  constructor(private modalService: NgbModal, private crud: CrudService, private router: Router, private helpService: HelpService) {
    this.deviceDetail = this.getBrowserDetails();
    this.customerDetails = this.getDefaultKYCValues();
    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = false;
    };
  }

  ngOnInit() {
  }

  // gets browser details
  private getBrowserDetails(): BrowserDetailsModel {
    return {
      appCodeName: navigator.appCodeName,
      appName: navigator.appName,
      appVersion: navigator.appVersion,
      userAgent: navigator.userAgent,
      platform: navigator.platform
    };
  }

  // contains user's kyc information values
  private getDefaultKYCValues(): UserFormModel {
    return {
      firstName: null,
      lastName: null,
      gender: null,
      dateOfBirth: null,
      address: null,
      email: null,
      docImgUrl: null,
      docNumber: null,
      docType: null
    };
  }


  ngOnChanges() {
    if (this.showModal) {
      setTimeout(() => {
        this.openOTPModal(this.content);
      }, 1000);
    } else {
      this.closeModal();
    }
  }

  // opens otp modal
  public openOTPModal(content): void {
    this.modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      backdrop: false,
      keyboard: false
    });
    this.modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  // closes otp modal
  public closeModal() {
    this.modalRef.close();
    this.numberVerifyCode = '';
    if (!this.isOTPVerified) {
      this.otpVerified.next(false);
    } else {
      sessionStorage.clear();
    }
  }

  // appends otp entered by user
  public otpVal(event, value: string): void {
    this.numberVerifyCode = this.firstVal.toString() + this.secondVal.toString() + this.thirdVal.toString() + this.fourthVal.toString();
    if (event.inputType !== 'deleteContentBackward') {
      switch (this.numberVerifyCode.length) {
        case 0:
          const id1 = document.getElementById('otp1');
          id1.focus();
          break;
        case 1:
          const id2 = document.getElementById('otp2');
          id2.focus();
          break;
        case 2:
          const id3 = document.getElementById('otp3');
          id3.focus();
          break;
        case 3:
          const id4 = document.getElementById('otp4');
          id4.focus();
          break;
      }
    }
    if (this.numberVerifyCode.length === 4) {
      this.verifyOTP();
    }
  }

  // resend's OTP
  public resendOTP(): void {
    this.isLoading = true;
    this.crud.getData(`user/resend/otp/${this.userNumber}`, 'auth').subscribe((res: any) => {
      this.isLoading = false;
      if (res.response_code !== 200) {
        this.isError = true;
        return;
      }
      this.otpId = res.response_data._id;
      this.resetOTPFields();
      this.isError = false;
      this.testDuration = 5;
      this.enableResend = false;
    }, error => {
      this.isLoading = false;
      this.isError = true;
    });
  }

  // reset otp field values
  private resetOTPFields(): void {
    this.firstVal = '';
    this.secondVal = '';
    this.thirdVal = '';
    this.fourthVal = '';
    this.numberVerifyCode = '';
  }

  // verifies otp
  public verifyOTP(): void {
    if (!this.forAuthentication) {
      this.verifySMSOtp();
    } else {
      this.verifyMFSPin();
    }
  }

  // verifies sms OTP
  private verifySMSOtp(): void {
    const body = {
      numberVerifyCode: this.numberVerifyCode,
      loginBy: 'web',
      deviceDetail: this.deviceDetail
    };
    this.isLoading = true;
    this.crud.updateRecord(`user/otp/verify/${this.otpId}`, body, 'auth').subscribe((res: any) => {
      this.isLoading = false;
      if (res.response_code !== 200) {
        this.isError = true;
        this.resetOTPFields();
        return;
      }
      this.isError = false;
      this.crud.setToken = res.response_data.data.token;
      const encodedString = btoa(res.response_data.data.token);
      sessionStorage.setItem('token', encodedString);
      this.crud.getData('user/me', 'auth').subscribe((response: any) => {
        if (response.response_code !== 200) {
          return;
        }
        sessionStorage.setItem('id', response.response_data.data._id);
        sessionStorage.setItem('simType', String(response.response_data.data.simType))
        const encodedData = btoa(JSON.stringify(response.response_data.data));
        sessionStorage.setItem('userData', encodedData);
        if (this.fromLoginPage) {
          this.closeModal();
          this.router.navigate(['airteltigo/home']);
        } else {
          if (res.response_data.data.firstTimeLogin) {
            this.isOTPVerified = true;
            if (res.response_code !== 200) {
              this.customerDetails = this.getDefaultKYCValues();
            } else {
              if (res.response_data.data.customerDetail) {
                const customerData: CustomerDetailsModel = res.response_data.data.customerDetail;
                this.customerDetails = {
                  firstName: customerData.firstName,
                  lastName: customerData.lastName,
                  gender: customerData.gender,
                  email: customerData['email'],
                  address: customerData.address,
                  dateOfBirth: this.formatDOB(customerData.dateOfBirth),
                  docType: customerData.docType,
                  docNumber: customerData.docNumber,
                  docImgUrl: null
                };
              }
            }
          } else {
            this.isOTPVerified = false;
            this.modalRef.close();
            setTimeout(() => {
              this.otpVerified.next(true);
            }, 100);
          }
        }
      }, error => {
        this.isLoading = false;
      });
    }, error => {
      this.isLoading = false;
      this.isError = true;
      this.resetOTPFields();
    });
  }

  // verifies MFS PIN
  private verifyMFSPin(): void {
    const userInfo: UserInfoModel = JSON.parse(atob(sessionStorage.getItem('userData')));
    const body = {
      mobileNumber: userInfo.mobileNumber,
      mpin: this.helpService.encodeMfsData(this.numberVerifyCode)
    };
    this.isLoading = true;
    this.crud.saveData('mfs/authenticate', body, 'mfs').subscribe((res: any) => {
      this.isLoading = false;
      if (res.response_code !== 200) {
        this.isError = true;
        this.resetOTPFields();
        const id1 = document.getElementById('otp1');
        id1.focus();
        return;
      }
      // if (this.fromHomePage) {
      //   this.modalRef.close();
      //   this.otpVerified.next(true);
      //   return;
      // }
      this.isError = false;
      this.pinVerified.next(this.numberVerifyCode);
      this.modalRef.close();
    }, error => {
      this.isLoading = false;
      this.isError = true;
      this.resetOTPFields();
    });
  }

  // submits kyc data
  public submitKYC(): void {
    if (!this.isDocImageSelected) {
      this.sendsUpdateProfileStatus();
    } else {
      this.uploader.uploadAll();
      this.uploader.onSuccessItem = (item: any, response: string, status: number, headers: any) => {
        const uploadedRes = JSON.parse(response);
        this.customerDetails.docImgUrl = environment.AUTH_URL + 'uploads/' + uploadedRes.response_data.data.fileName;
        this.sendsUpdateProfileStatus();
      };
    }
  }

  // sends update profile status
  public sendsUpdateProfileStatus(): void {
    this.customerDetails.docImgUrl = this.isDocImageSelected ? this.customerDetails.docImgUrl : undefined;
    if (this.customerDetails.dateOfBirth) {
      this.customerDetails.dateOfBirth = this.customerDetails.dateOfBirth['year'] + '-' + this.customerDetails.dateOfBirth['month'] + '-' + this.customerDetails.dateOfBirth['day'];
    }
    const body = {
      copyCustomerDetails: this.customerDetails
    };
    this.crud.updateRecord('user/profile/update/request', body, 'auth').subscribe((res: any) => {
      if (res.response_code !== 200) {
        alert('Something went wrong.Please try again');
        return;
      }
      this.isOTPVerified = false;
      this.modalRef.close();
      setTimeout(() => {
        this.otpVerified.next(true);
      }, 100);
    });
  }

  // formats dob
  private formatDOB = (date: any) => {
    if (date) {
      const dob = String(date).split('-');
      if (dob[2].length > 2) {
        dob[2] = dob[2].slice(0, 2);
      }
      return <any>{year: Number(dob[0]), month: Number(dob[1]), day: Number(dob[2])};
    }
    return null;
  };

  // contains modal dismissal reason
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
