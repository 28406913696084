import {AirtimeBalanceModel} from '../pages/models/airtime.model';
import * as AccountBalanceAction from './account-balance.action';

export interface BalanceInfoState {
  balanceInfo: AccountBalanceState;
  mfsBalance: number;
  creditLimit: number;
}

export interface AccountBalanceState {
  balanceInfo: AirtimeBalanceModel;
  mfsBalance: number;
  creditLimit: number;
}

export const initialState: AccountBalanceState = {
  balanceInfo: getDefaultValues(),
  mfsBalance: 0,
  creditLimit: 0
};

function getDefaultValues(): AirtimeBalanceModel {
  return {
    airtime: 0,
    internet: 0,
    point: 0
  };
}

export function accountBalanceReducer(state = initialState, action: AccountBalanceAction.AccountBalanceAction) {
  switch (action.type) {
    case AccountBalanceAction.SET_BALANCE: {
      return {
        ...state,
        balanceInfo: action.payload.balanceInfo,
        mfsBalance: state.mfsBalance > 0 ? state.mfsBalance : 0,
        creditLimit: state.creditLimit
      };
    }
    case AccountBalanceAction.RESET_BALANCE:
      return {
        ...state,
        balanceInfo: getDefaultValues(),
        mfsBalance: 0,
        creditLimit: state.creditLimit
      };
    case AccountBalanceAction.SET_MFS_BALANCE:
      return {
        ...state,
        balanceInfo: state.balanceInfo,
        mfsBalance: Number(action.payload),
        creditLimit: state.creditLimit
      };
    case AccountBalanceAction.SET_CREDIT_LIMIT:
      return {
        ...state,
        balanceInfo: state.balanceInfo,
        mfsBalance: state.mfsBalance,
        creditLimit: action.payload.creditLimit
      };
    default:
      return state;
  }
}
