import { Component, OnInit } from "@angular/core";
import { AirtimeBalanceModel } from "../../pages/models/airtime.model";
import { CrudService } from "../../services/crud.service";
import { HelpService } from "../../services/help.service";
import { AuthorizedLayoutComponent } from "../authorized-layout/authorized-layout.component";
import { QuickActionsModel } from "../../pages/models/quick-actions.model";
import * as fromReducer from "../../app-store/account-balance.reducer";
import { Store } from "@ngrx/store";

@Component({
  selector: "app-sidepanel",
  templateUrl: "./sidepanel.component.html",
  styleUrls: ["./sidepanel.component.scss"],
})
export class SidepanelComponent implements OnInit {
  public airtimeBalanceData: AirtimeBalanceModel; // contains user's airtime information
  public quickActions: Array<QuickActionsModel> = []; // contains list of quick actions
  public rbtStatus: boolean = null; // contains user's rbt status
  public isMoneySection: boolean = false; // shows money balance when true
  public showOTPModal: boolean = false; // shows MFS OTP Modal when true
  public mfsBalance: number = 0; // contains user's airteltigo money balance
  public simType: number = null; // contains simtype of the user (0 = Prepaid, 1 = Postpaid)
  public creditLimit: number = 0; // contains user's credit limit ( shown only for postpaid users)

  constructor(
    private crud: CrudService,
    private helpService: HelpService,
    private authorized: AuthorizedLayoutComponent,
    private store: Store<fromReducer.BalanceInfoState>
  ) {
    this.simType = Number(sessionStorage.getItem("simType"));
    this.airtimeBalanceData = this.getDefaultAirtimeValues();
    this.getQuickActions();
  }

  ngOnInit() {
    this.helpService.moneysection.subscribe((status) => {
      this.mfsBalance = 0;
      this.isMoneySection = status;
    });
    this.store.select("balanceInfo").subscribe((state) => {
      this.airtimeBalanceData = state.balanceInfo;
      this.creditLimit = state.creditLimit;
    });

    this.helpService.quickActions.subscribe((status) => {
      if (status) {
        this.helpService.quickActionStatus(false);
        this.getQuickActions();
      }
    });
    this.helpService.rbtInfo.subscribe((status) => {
      if (status) {
        this.rbtStatus = sessionStorage.getItem("RBT-status")
          ? Boolean(Number(sessionStorage.getItem("RBT-status")))
          : false;
        this.helpService.emitRBTStatus(false);
      }
    });
  }

  // gets list of user's quick action
  private getQuickActions(): void {
    this.crud.getData("quickaction", "auth").subscribe(
      (res: any) => {
        this.quickActions =
          res.response_code === 200 ? res.response_data.data : [];
        this.helpService.quickActionStatus(false);
      },
      (error) => {
        this.quickActions = [];
      }
    );
  }

  // gets query params
  public getQueryParams(serialNumber: number) {
    switch (serialNumber) {
      case 1:
        return null;
      case 2:
        return { id: 2 };
      case 3:
        return { id: 4 };
      case 4:
        return { id: 3 };
      case 5:
        return null;
      case 6:
        return { id: 0 };
      case 7:
        return { id: 1 };
      case 8:
        return { id: 2 };
      case 9:
        return { id: 3 };
      case 10:
        return { id: 4 };
    }
  }

  // gets default airtime values
  private getDefaultAirtimeValues(): AirtimeBalanceModel {
    return {
      airtime: 0,
      internet: 0,
      point: 0,
    };
  }

  // get updated airtime balance info
  public getAirtimeBalance() {
    this.authorized.getUserBalanceInfo();
  }

  // gets user's MFS balance
  public otpVerified(mfsPin) {
    this.showOTPModal = false;
    const pin = this.helpService.encodeMfsData(mfsPin);
    this.crud.getData(`mfs/check/balance/${pin}`, "mfs").subscribe(
      (res: any) => {
        if (res.response_code !== 200) {
          alert(
            "Something went wrong while fetching your AT money balance, Please try again"
          );
          return;
        }
        this.mfsBalance = Number(res.response_data.data);
      },
      (error) => {
        alert(
          "Something went wrong while fetching your AT money balance, Please try again"
        );
      }
    );
  }
}
