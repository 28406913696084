import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-error-component',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  public videoPath: string = environment.s3Bucket + 'error.mp4';
  public errorData = {
    message: null,
    route: null
  };

  constructor() {
    if (sessionStorage.getItem('errorData')) {
      this.errorData = JSON.parse(sessionStorage.getItem('errorData'));
      sessionStorage.removeItem('errorData');
    }
  }

  ngOnInit(): void {
  }
}
