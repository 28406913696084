import {Injectable} from '@angular/core';
import {HelpService} from '../services/help.service';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {EMPTY, Observable} from 'rxjs';
import {Action} from '@ngrx/store';
import * as AccountBalanceAction from './account-balance.action';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {AccountBalanceState} from './account-balance.reducer';
import * as fromReducer from './account-balance.reducer';

@Injectable()
export class AccountBalanceEffects {
  constructor(private helpService: HelpService, private actions$: Actions) {

  }

  @Effect()
  public loadBalanceInfo$: Observable<Action> = this.actions$.pipe(
    ofType<AccountBalanceAction.AccountBalanceGetAction>(AccountBalanceAction.GET_BALANCE),
    mergeMap(() => this.helpService.getUserAccountBalance().pipe(
      map((balanceInfo) => ({type: AccountBalanceAction.SET_BALANCE, payload: this.mapAPIResponse(balanceInfo)})),
      catchError((error) => EMPTY)
    )));

  @Effect()
  public loadMFSBalance$: Observable<Action> = this.actions$.pipe(
    ofType<AccountBalanceAction.GetMFSBalanceAction>(AccountBalanceAction.GET_MFS_BALANCE),
    mergeMap(() => this.helpService.getUserMFSBalance().pipe(
      map((data) => ({type: AccountBalanceAction.SET_MFS_BALANCE, payload: data.response_data.data.balance})),
      catchError((error) => EMPTY)
    )));

  @Effect()
  public loadUserCreditLimit$: Observable<Action> = this.actions$.pipe(
    ofType<AccountBalanceAction.GetPostPaidCreditLimit>(AccountBalanceAction.GET_CREDIT_LIMIT),
    mergeMap(() => this.helpService.getPostPaidUserCreditLimit().pipe(
      map((data) => ({type: AccountBalanceAction.SET_CREDIT_LIMIT, payload: {creditLimit: data.response_data.data}})),
      catchError((error) => EMPTY)
    ))
  );

  // takes the api response and returns only the balance info
  private mapAPIResponse(balanceInfo): AccountBalanceState {
    return (balanceInfo.response_code === 200 ? {
      balanceInfo: balanceInfo.response_data.data,
      mfsBalance: fromReducer.initialState.mfsBalance,
      creditLimit: fromReducer.initialState.creditLimit
    } : {
      balanceInfo: {
        airtime: 0,
        internet: 0,
        point: 0
      },
      mfsBalance: fromReducer.initialState.mfsBalance,
      creditLimit: fromReducer.initialState.creditLimit
    });
  }
}
