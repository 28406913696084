import {Component, OnInit, Output, EventEmitter, Input, OnChanges, OnDestroy} from '@angular/core';
import {BuyProductsModel} from '../../models/products.model';
import {OrderModel} from '../../models/auth-models.model';
import {CrudService} from '../../../services/crud.service';
import {Router} from '@angular/router';
import {HelpService} from '../../../services/help.service';

@Component({
  selector: 'app-payment-confirmation',
  templateUrl: './payment-confirmation.component.html',
  styleUrls: ['./payment-confirmation.component.scss']
})
export class PaymentConfirmationComponent implements OnInit, OnChanges, OnDestroy {
  @Output() closePaymentConfirmation = new EventEmitter<boolean>();
  @Output() paymentCompleted = new EventEmitter<Object>();
  @Input() paymentInfo;                         // contains payment related information
  @Input() productData: any;       // contains information about product
  public isAddedToFavourite: boolean = false;     // set to true when user is added to favourites
  public isLoading: boolean = false;          // shows loader when true
  @Input() orderDetail: OrderModel;
  public showDefaultBox = true;               // shows default box when true
  @Output() errorResponse = new EventEmitter<Object>();       // emits when there is an error in CBS server
  public showOTP: boolean = false;          // show's otp modal when true
  public forAuthentication: boolean = false;        // set to true if selected for authentication
  public rbtStatus: boolean = false;        // contains user's RBT (Ring back tones registration status) status
  public simType: number = null;     // contains sim type of logged in user (0 = Prepaid, 1 = Postpaid)

  constructor(private crud: CrudService, private router: Router, private help: HelpService) {
    this.simType = Number(sessionStorage.getItem('simType'));
    this.rbtStatus = sessionStorage.getItem('RBT-status') === '1';
    if (sessionStorage.getItem('payment-data')) {
      this.orderDetail = JSON.parse(sessionStorage.getItem('payment-data'));
      this.showDefaultBox = false;
    }
  }

  // gets user's favourites list
  private getUserFavourites(): void {
    this.crud.getData('favouritecontact', 'auth').subscribe((res: any) => {
      if (res.response_code !== 200) {
        this.isAddedToFavourite = false;
        return;
      }
      let checkIfFavourite;
      if (this.paymentInfo.type === 'Product') {
        checkIfFavourite = res.response_data.data.find(favourite => favourite.favNumber === this.productData.otherNumber);
      } else {
        if (this.paymentInfo.how === 'Transfer') {
          checkIfFavourite = res.response_data.data.find(favourite => favourite.favNumber === this.productData.receiverMobileNumber);
        } else if (this.paymentInfo.how === 'Scratch Card') {
          checkIfFavourite = res.response_data.data.find(favourite => favourite.favNumber === this.productData.mobileNumber);
        } else if (this.paymentInfo.how === 'Airteltigo Points') {
          checkIfFavourite = res.response_data.data.find(favourite => favourite.favNumber === this.productData.mobileNumber);
        } else {
          checkIfFavourite = res.response_data.data.find(favourite => favourite.favNumber === this.productData.mobileNumber);
        }
      }
      this.isAddedToFavourite = checkIfFavourite !== undefined;
    }, error => {
      this.isAddedToFavourite = false;
    });
  }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.paymentInfo === undefined) {
      this.showDefaultBox = false;
    }
    this.getUserFavourites();
  }

  // closes payment confirmation modal
  public closePayment(): void {
    this.closePaymentConfirmation.next(true);
  }

  // navigates to home page
  public goToAuthHome() {
    this.help.emitCurrentStepData(null);
    this.router.navigate(['auth/home']);
  }

  // process payment and add's product to user's subscription
  public processPayment(): void {
    if (this.paymentInfo) {
      switch (this.paymentInfo.type) {
        case 'Product':
          this.purchaseDataRelatedProducts();
          break;
        case 'Airtime':
          this.purchaseAirtime();
          break;
        case 'RBT':
          this.sendRequestToDownloadSong();
          break;
        default:
          this.placesNewSimOrder();
          break;
      }
    } else {
      this.placesNewSimOrder();
    }
  }

  // send's request to download a song
  private sendRequestToDownloadSong(): void {
    this.isLoading = true;
    this.crud.getData(`rbt/song/download/${this.productData.contentId}`, 'mfs').subscribe((res: any) => {
      this.isLoading = false;
      if (res.response_code !== 200) {
        const errorBody = {
          error: true,
          message: res.response_data.msg
        };
        this.handleErrorResponse(errorBody);
        return;
      }
      const successBody = {
        success: true,
        message: 'Song downloaded successfully'
      };
      this.paymentCompleted.next(successBody);
    }, error => {
      this.isLoading = false;
      const errorBody = {
        error: true,
        message: 'Something went wrong, Please try again.'
      };
      this.handleErrorResponse(errorBody);
    });
  }

  // place new sim order
  private placesNewSimOrder(): void {
    this.isLoading = true;
    this.crud.saveData('order', this.orderDetail, 'auth').subscribe((res: any) => {
      console.log(res);
      this.isLoading = false;
      if (res.response_code === 200) {
        const body = {
          heading: 'Order Completed',
          body1: 'We will contact you when it\'s ready. Thank you for joining the big family!',
          body2: null,
          route: '/auth/home'
        };
        this.router.navigate(['success']);
        sessionStorage.setItem('success-result', JSON.stringify(body));
      }
    }, error => {
      this.isLoading = false;
      console.log('error');
    });
  }

  // purchases data,SMS and talk time related products
  private purchaseDataRelatedProducts(): void {
    if (this.paymentInfo.how === 'Airtime') {
      if (!this.productData.changeServiceClass) {
        this.purchaseProductThroughAirtimeOrGift();
      } else {
        this.changeServiceClass();
      }
    } else if (this.paymentInfo.how === 'Borrow Internet') {
      this.sendBorrowInternetRequest();
    } else if (this.paymentInfo.how === 'PostPaid') {
      this.sendRequestToBuyPostPaidProduct();
    } else {
      this.showAuthenticationModal();
    }
  }

  // sends request to buy postpaid product
  private sendRequestToBuyPostPaidProduct() {
    let body = {
      postPaidSubCategoryId: '5daeca654542631cc8d21164',
      postPaidProductId: '5daee408cc8a6127cd42529c',
      productId: 2018105030,
      serviceClassId: '',
      changeServiceClass: '',
      channel: 'Web App',
      mobileNumber: this.productData.userNumber,
      payBy: 'PostPaid',
      orderType: 'PostPaid',
      title: this.productData.title,
      amount: this.productData.amount
    };
    this.crud.saveData('postpaidorder', body, 'user').subscribe((res: any) => {
      if (res.response_code !== 200) {
        const body = {
          error: true,
          message: 'Could not process your request, Please try again',
          type: 'Borrow internet'
        };
        this.handleErrorResponse(body);
        return;
      }
      const successBody = {
        success: true,
        message: 'We have received your request to purchase this product, It will be processed shortly. Thank you'
      };
      this.paymentCompleted.next(successBody);
    }, error => {
      console.log(error);
    });
  }

  // changes user's service class
  private changeServiceClass(mpin?: string): void {
    const body = {
      mobileNumber: this.paymentInfo.isSameNumber ? this.paymentInfo.mobileNumber : this.productData.otherNumber,
      productId: this.productData.serviceClassId
    };
    this.isLoading = true;
    this.crud.saveData('user/check/main/product', body, 'user').subscribe((res: any) => {
      this.isLoading = false;
      if (res.response_code !== 200) {
        const errorBody = {
          error: true,
          message: res.response_data.message ? res.response_data.message : 'Could not change service class',
          type: this.paymentInfo.how
        };
        this.handleErrorResponse(errorBody);
        return;
      }
      delete this.productData.changeServiceClass;
      delete this.productData.serviceClassId;
      if (this.paymentInfo.how === 'Airtime') {
        this.purchaseProductThroughAirtimeOrGift();
      } else if (this.paymentInfo.how === 'AirtelTigo Money') {
        this.sendsProductPurchaseRequestThroughMFS(mpin);
      }
    }, error => {
      this.isLoading = false;
      const errorBody = {
        error: true,
        message: 'Something went wrong please try again'
      };
      this.handleErrorResponse(errorBody);
      return;
    });
  }

  // send's request to borrow internet
  private sendBorrowInternetRequest(): void {
    const data = {
      creditType: this.productData.creditType,
      grandTotal: this.productData.price,
      volume: this.productData.volume.split(' ').join(''),
      extracharge: this.productData.serviceFee,
      channel: 'Web App'
    };
    this.isLoading = true;
    this.crud.saveData('user/borrow/internet', data, 'user').subscribe((res: any) => {
      this.isLoading = false;
      if (res.response_code !== 200) {
        const body = {
          error: true,
          message: 'Could not process your request, Please try again',
          type: 'Borrow internet'
        };
        this.handleErrorResponse(body);
        return;
      }
      if (res.response_data.data.SubscriberStatus !== 201) {
        const errorBody = {
          error: true,
          message: res.response_data.data.ResponseContent,
          type: 'Borrow internet'
        };
        this.handleErrorResponse(errorBody);
        return;
      }
      const successBody = {
        success: true,
        message: 'Product purchased.'
      };
      this.paymentCompleted.next(successBody);
    }, error => {
      this.isLoading = false;
      const body = {
        error: true,
        message: 'Something went wrong, Please try again',
        type: 'Borrow internet'
      };
      this.handleErrorResponse(body);
    });
  }

  // shows Authentication Modal
  private showAuthenticationModal() {
    this.forAuthentication = true;
    this.showOTP = true;
  }

  // purchases product through airtime and gift
  private purchaseProductThroughAirtimeOrGift() {
    this.isLoading = true;
    this.crud.saveData('user/buy/netpack/by/airtime', this.productData, 'user').subscribe((res: any) => {
      this.isLoading = false;
      if (res.response_code === 200) {
        const body = {
          success: true,
          message: 'Product purchased.'
        };
        this.paymentCompleted.next(body);
      } else {
        const errorBody = {
          error: false,
          message: res.response_data.message || 'Could not process your request, Please try again.',
          type: 'Airtime'
        };
        this.handleErrorResponse(errorBody);
      }
    }, error => {
      this.isLoading = false;
      const errorBody = {
        error: false,
        message: 'Something went wrong, Please try again.',
        type: 'Airtime'
      };
      this.handleErrorResponse(errorBody);
    });
  }

  // called when user is successfully authenticated into mfs platform
  public mfsPINAuthenticated(mPin: string): void {
    if (this.paymentInfo.airtimeType !== 'Airteltigo Money') {
      if (!this.productData.changeServiceClass) {
        this.sendsProductPurchaseRequestThroughMFS(mPin);
      } else {
        this.changeServiceClass(mPin);
      }
    } else {
      if (this.paymentInfo.isAirteltigoUser === undefined) {
        this.sendMFSTopupRequest(mPin);
      } else if (this.paymentInfo.isAirteltigoUser === false) {
        this.topupOtherNetworkUser(mPin);
      } else {
        this.topupOtherAirtelTigoUser(mPin);
      }
    }
  }

  // sends request to recharge airtime to other airtime
  private topupOtherAirtelTigoUser(mPin: string): void {
    this.productData.mpin = this.help.encodeMfsData(mPin);
    this.productData.channel = 'Web App';
    this.isLoading = true;
    this.crud.saveData('mfs/buy/airtime/other/airtel/user', this.productData, 'mfs').subscribe((res: any) => {
      this.isLoading = false;
      if (res.response_code !== 200) {
        const errorBody = {
          error: true,
          message: res.response_data.message
        };
        this.handleErrorResponse(errorBody);
        return;
      }
      const successBody = {
        success: true,
        message: res.response_data.message
      };
      this.paymentCompleted.next(successBody);
    }, error => {
      this.isLoading = false;
      const errorBody = {
        error: true,
        message: 'Something went wrong, Please try again.'
      };
      this.handleErrorResponse(errorBody);
    });
  }

  // sends request to recharge other network number
  private topupOtherNetworkUser(mPin: string): void {
    this.productData.mpin = this.help.encodeMfsData(mPin);
    this.productData.channel = 'Web App';
    this.isLoading = true;
    this.crud.saveData('mfs/buy/airtime/other/network', this.productData, 'mfs').subscribe((res: any) => {
      this.isLoading = false;
      if (res.response_code !== 200) {
        const errorBody = {
          error: true,
          message: res.response_data.message.Message
        };
        this.handleErrorResponse(errorBody);
        return;
      }
      const successBody = {
        success: true,
        message: res.response_data.message
      };
      this.paymentCompleted.next(successBody);
    }, error => {
      this.isLoading = false;
      const errorBody = {
        error: true,
        message: 'Something went wrong, Please try again.'
      };
      this.handleErrorResponse(errorBody);
    });
  }

  // send's request to purchase product through MFS
  private sendsProductPurchaseRequestThroughMFS(mPin: string): void {
    const body = {
      mobileNumber: this.productData.isForSameNumber ? this.productData.userNumber : this.productData.otherNumber,
      mpin: this.help.encodeMfsData(mPin),
      volume: this.productData.title,
      amount: this.productData.amount,
      productId: this.productData.productId,
      isForSameNumber: this.productData.isForSameNumber,
      orderType: this.productData.orderType,
      subCategoryId: this.productData.subCategoryId,
      pid: this.productData.pid,
      channel: 'Web App'
    };
    this.showOTP = false;
    this.forAuthentication = false;
    this.isLoading = true;
    this.crud.saveData('mfs/payment', body, 'mfs').subscribe((res: any) => {
      this.isLoading = false;
      if (res.response_code === 200) {
        const body = {
          success: true,
          message: 'Product purchased.'
        };
        this.paymentCompleted.next(body);
      } else {
        const errorBody = {
          error: false,
          message: res.response_data.message ? res.response_data.message : 'Could not process your request, Please try again.',
          type: 'MFS'
        };
        this.handleErrorResponse(errorBody);
      }
    }, error => {
      this.isLoading = false;
      const errorBody = {
        error: false,
        message: 'Something went wrong, Please try again.',
        type: 'MFS'
      };
      this.handleErrorResponse(errorBody);
    });
  }

  // purchases airtime through various modes
  private purchaseAirtime() {
    switch (this.paymentInfo.airtimeType) {
      case 'Scratch Card':
        this.voucherCardRecharge();
        break;
      case 'Airteltigo Money':
        this.showAuthenticationModal();
        break;
      case 'Borrow Airtime':
        this.sendBorrowAirtimeRequest();
        break;
      case 'Points':
        this.purchaseThroughPoints();
        break;
      case 'Transfer':
        this.transferToFriendRecharge();
        break;
    }
  }

  // send's request to topup airtime using MFS
  private sendMFSTopupRequest(mPin: string): void {
    this.productData.mpin = this.help.encodeMfsData(mPin);
    this.isLoading = true;
    this.productData.channel = 'Web App';
    this.crud.saveData('mfs/buy/airtime', this.productData, 'mfs').subscribe((res: any) => {
      this.isLoading = false;
      if (res.response_code !== 200) {
        const eBody = {
          error: true,
          message: res.response_data.message
        };
        this.handleErrorResponse(eBody);
        return;
      }
      const successBody = {
        success: true,
        message: res.response_data.message
      };
      this.paymentCompleted.next(successBody);
    }, error => {
      this.isLoading = false;
      const errorBody = {
        error: true,
        message: 'Something went wrong, Please try again'
      };
      this.handleErrorResponse(errorBody);
    });
  }

  // sends request to borrow airtime
  private sendBorrowAirtimeRequest(): void {
    const body = {
      creditType: this.productData.creditType,
      extracharge: this.productData.fee - this.productData.amount,
      channel: 'Web App'
    };
    this.isLoading = true;
    this.crud.saveData('user/borrow/airtime', body, 'user').subscribe((res: any) => {
      this.isLoading = false;
      if (res.response_code !== 200) {
        const errorBody = {
          error: true,
          message: res.response_data.message
        };
        this.handleErrorResponse(errorBody);
        return;
      }
      if (res.response_data.data.SubscriberStatus !== 201) {
        const errorBody = {
          error: true,
          message: res.response_data.data.ResponseContent
        };
        this.handleErrorResponse(errorBody);
        return;
      }
      const successBody = {
        success: true,
        message: 'Airtime Recharged'
      };
      this.paymentCompleted.next(successBody);
    }, error => {
      this.isLoading = false;
      const errorBody = {
        error: true,
        message: 'Something went wrong'
      };
      this.handleErrorResponse(errorBody);
    });
  }

  // purchases airtime through voucher card
  private voucherCardRecharge(): void {
    this.isLoading = true;
    const data = {
      isForSameNumber: this.productData.isForSameNumber,
      mobileNumber: this.productData.mobileNumber,
      voucherNumber: String(this.productData.voucherNumber),
      channel: 'Web App'
    };
    const url = this.productData.rechargeType === 'Airtime' ? 'user/voucher/airtime/recharge' : 'user/voucher/internet/recharge';
    this.crud.saveData(url, data, 'user').subscribe((res: any) => {
      this.isLoading = false;
      if (res.response_code !== 200) {
        const body = {
          error: true,
          message: res.response_data.message ? res.response_data.message : 'Voucher code is invalid or does not exist.',
          type: 'Scratch Card'
        };
        this.handleErrorResponse(body);
        return;
      }
      const body = {
        success: true,
        message: this.productData.rechargeType === 'Airtime' ? 'Airtime Recharged' : res.response_data.message,
      };
      this.paymentCompleted.next(body);
    }, error => {
      this.isLoading = false;
      const body = {
        error: true,
        message: 'Something went wrong, Please try again.',
        type: 'Scratch Card'
      };
      this.handleErrorResponse(body);
    });
  }

  // purchases airtime through loyalty points
  private purchaseThroughPoints(): void {
    this.isLoading = true;
    const body = {
      amount: this.productData.amount,
      otherNumber: this.productData.mobileNumber,
      isForSameNumber: this.productData.isForSameNumber
    };
    this.crud.saveData('user/buy/airtime/by/point', body, 'user').subscribe((res: any) => {
      this.isLoading = false;
      console.log(res);
      if (res.response_code !== 200) {
        const body = {
          error: true,
          message: res.response_data.message ? res.response_data.message : 'Could not process the transaction.Please try again.'
        };
        this.handleErrorResponse(body);
        return;
      }
      const body = {
        success: true,
        message: 'Airtime recharged'
      };
      this.paymentCompleted.next(body);
    }, error => {
      this.isLoading = false;
      console.log(error);
    });
  }

  // transfer to friend recharge
  private transferToFriendRecharge(): void {
    this.isLoading = true;
    this.crud.saveData('user/airtime/transfer/to/friend', this.productData, 'user').subscribe((res: any) => {
      this.isLoading = false;
      if (res.response_code !== 200) {
        const body = {
          error: true,
          message: res.response_data.message ? res.response_data.message : 'Could not transfer the amount.Please try again.'
        };
        this.handleErrorResponse(body);
        return;
      }
      const body = {
        success: true,
        message: 'Airtime recharged'
      };
      this.paymentCompleted.next(body);
    }, error => {
      this.isLoading = false;
      const body = {
        error: true,
        message: 'Could not transfer the amount.Please try again.'
      };
      this.handleErrorResponse(body);
    });
  }

  // handles error
  private handleErrorResponse(body): void {
    this.errorResponse.next(body);
  }

  // add's recipient as favourite
  public addToFavourite(): void {
    let body = {
      favName: this.paymentInfo.to,
      favNumber: this.productData.otherNumber
    };
    if (body.favNumber === undefined) {
      body.favNumber = this.paymentInfo.how === 'Scratch Card' ? this.productData.mobileNumber : null;
      if (body.favNumber === undefined || body.favNumber === null) {
        body.favNumber = this.paymentInfo.how === 'Transfer' ? this.productData.receiverMobileNumber : null;
      }
      if (body.favNumber === undefined || body.favNumber === null) {
        body.favNumber = this.paymentInfo.how === 'Airteltigo Money' ? this.productData.mobileNumber : null;
      }
    }

    this.crud.saveData('favouritecontact', body, 'auth').subscribe((res: any) => {
      this.isAddedToFavourite = res.response_code === 200;
    }, error => {
      this.isAddedToFavourite = false;
    });
  }

  ngOnDestroy(): void {
    sessionStorage.removeItem('payment-data');
  }

}
