import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[appNumberOnly]'
})
export class NumberOnlyDirective {
  private current: string;        // contains input entered bu user
  private next: string;           // contains input after concatenating with user input
  private expression = new RegExp(/^[0-9]+(\.[0-9]*){0,1}$/g);        // allows only number and decimal
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'Home', 'End', 'ArrowRight', 'ArrowLeft'];     // list of special keys

  constructor(private el: ElementRef) {

  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    this.current = this.el.nativeElement.value;
    this.next = this.current.concat(event.key);
    if (this.next && !String(this.next).match(this.expression)) {
      event.preventDefault();
    }
  }
}
