import {Component, OnInit} from '@angular/core';
import {CrudService} from '../../services/crud.service';
import {Router} from '@angular/router';
import {UserInfoModel} from '../../pages/models/user.model';
import {HelpService} from '../../services/help.service';
import {AirtimeBalanceModel} from '../../pages/models/airtime.model';
import {environment} from '../../../environments/environment';
import * as AccountBalanceAction from '../../app-store/account-balance.action';
import {Store} from '@ngrx/store';
import {BalanceInfoState} from '../../app-store/account-balance.reducer';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public tabProfileMenu = false;
  public sidemenuActive = false;
  public profileactive = false;
  public avtarDeactive = true;
  public userInfo: UserInfoModel;           // contains information about logged in user
  public imageUrl: string = 'assets/img/svg-icon/silhouette.svg';
  public simType: number;     // contains sim type of user

  constructor(private crud: CrudService, private router: Router, private helpService: HelpService, private store: Store<BalanceInfoState>) {
    if (sessionStorage.getItem('userData')) {
      this.userInfo = JSON.parse(atob(sessionStorage.getItem('userData')));
      this.simType = Number(sessionStorage.getItem('simType'));
      if (!this.userInfo.customerDetails) {
        this.userInfo.customerDetails = this.helpService.getDefaultCustomerInfo();
        this.userInfo.customerDetails.firstName = 'Airteltigo user';
      }
      if (this.userInfo.profileImageUrl) {
        this.imageUrl = this.userInfo.profileImageUrl;
      }
    }
  }

  ngOnInit() {
    this.helpService.imageInfo.subscribe(data => {
      if (data) {
        this.imageUrl = data;
      }
    });
  }

  // log's out the user
  public logout(): void {
    this.crud.setToken = null;
    sessionStorage.clear();
    this.store.dispatch(new AccountBalanceAction.ResetAccountBalanceAction());
    this.router.navigate(['auth/home']);
  }

  // shows alert
  public showAlert(): void {
    alert('Coming soon. Thank you');
  }

  // handles broken images
  public handleBrokenImages(): void {
    this.imageUrl = 'assets/img/svg-icon/silhouette.svg';
    console.clear();
  }

  openProfile() {
    this.tabProfileMenu = !this.tabProfileMenu;
    this.profileactive = !this.profileactive;
    this.avtarDeactive = !this.avtarDeactive;
    this.sidemenuActive = false;
  }

  openSidemenu() {
    this.sidemenuActive = !this.sidemenuActive;
  }

  closeSidemenu() {
    this.sidemenuActive = false;
  }
}
