import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {CrudService} from './crud.service';
import {catchError, map, tap} from 'rxjs/operators';

@Injectable()
export class CrudInterceptorService implements HttpInterceptor {
  constructor(private crud: CrudService) {

  }

  // intercepts every http request and modifies request headers accordingly
  public intercept(request: HttpRequest<any>, handler: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url !== 'http://41.215.168.134:2000/') {
      let headers = {
        'Content-Type': 'application/json'
      };
      if (this.crud.getToken) {
        headers['Authorization'] = this.crud.getToken;
      }
      const body = request.body;
      let encryptedBody = {
        required: true,
        accessToken: null,
        body: null,
      };
      if (request.method == 'POST' || request.method == 'PUT') {
        encryptedBody.body = this.enceryptPayload(request.body);
        encryptedBody.accessToken = this.enceryptPayload(Date.now());
      }
      request = request.clone({
        setHeaders: headers,
        body: encryptedBody || undefined
      });
    }
    return handler.handle(request).pipe(
      map((evt: HttpResponse<any>) => {
        if (evt instanceof HttpResponse) {
          if (evt.body.response) {
            const decryptedData = this.decryptBody(evt.body.response);
            return evt.clone({
              body: JSON.parse(decryptedData)
            });
          } else if (evt.body.response_data) {
            return evt;
          }
        }
      }),
      catchError((err: any) => {
        // if(err instanceof HttpErrorResponse){
        const decryptedData = this.decryptBody(err.error.text);
        console.log('Error', err);
        return of(err);
        // }
      })
    );
  }

  // FIXME:// converts body to base 64 and modifies the string
  private enceryptPayload(json): string {
    const str = btoa(JSON.stringify(json));
    let encryptedString: string = '';
​
    for (var i = 0; i < str.length; i++) {
      switch (str[i]) {
        case 'A':
          encryptedString += 'l';
          break;
        case 'B':
          encryptedString += 'q';
          break;
        case 'C':
          encryptedString += 'Z';
          break;
        case 'D':
          encryptedString += 'j';
          break;
        case 'E':
          encryptedString += '0';
          break;
        case 'F':
          encryptedString += 'p';
          break;
        case 'G':
          encryptedString += 'V';
          break;
        case 'H':
          encryptedString += 'A';
          break;
        case 'I':
          encryptedString += 'o';
          break;
        case 'J':
          encryptedString += 'R';
          break;
        case 'K':
          encryptedString += 'd';
          break;
        case 'L':
          encryptedString += 'i';
          break;
        case 'M':
          encryptedString += 'r';
          break;
        case 'N':
          encryptedString += '6';
          break;
        case 'O':
          encryptedString += 'x';
          break;
        case 'P':
          encryptedString += 'k';
          break;
        case 'Q':
          encryptedString += '7';
          break;
        case 'R':
          encryptedString += '1';
          break;
        case 'S':
          encryptedString += 'h';
          break;
        case 'T':
          encryptedString += 'n';
          break;
        case 'U':
          encryptedString += 'y';
          break;
        case 'V':
          encryptedString += 'B';
          break;
        case 'W':
          encryptedString += 'E';
          break;
        case 'X':
          encryptedString += 'L';
          break;
        case 'Y':
          encryptedString += 't';
          break;
        case 'Z':
          encryptedString += 'H';
          break;
        case 'a':
          encryptedString += 'D';
          break;
        case 'b':
          encryptedString += 'Q';
          break;
        case 'c':
          encryptedString += 'u';
          break;
        case 'd':
          encryptedString += 'F';
          break;
        case 'e':
          encryptedString += 'm';
          break;
        case 'f':
          encryptedString += '5';
          break;
        case 'g':
          encryptedString += 'C';
          break;
        case 'h':
          encryptedString += 'a';
          break;
        case 'i':
          encryptedString += 'S';
          break;
        case 'j':
          encryptedString += 'G';
          break;
        case 'k':
          encryptedString += '8';
          break;
        case 'l':
          encryptedString += 'O';
          break;
        case 'm':
          encryptedString += 'I';
          break;
        case 'n':
          encryptedString += '4';
          break;
        case 'o':
          encryptedString += 'K';
          break;
        case 'p':
          encryptedString += '+';
          break;
        case 'q':
          encryptedString += '2';
          break;
        case 'r':
          encryptedString += 'J';
          break;
        case 's':
          encryptedString += 'T';
          break;
        case 't':
          encryptedString += 'M';
          break;
        case 'u':
          encryptedString += 'W';
          break;
        case 'v':
          encryptedString += 'N';
          break;
        case 'w':
          encryptedString += '/';
          break;
        case 'x':
          encryptedString += 'P';
          break;
        case 'y':
          encryptedString += 'X';
          break;
        case 'z':
          encryptedString += 'U';
          break;
        case '0':
          encryptedString += 'Y';
          break;
        case '1':
          encryptedString += 'g';
          break;
        case '2':
          encryptedString += 's';
          break;
        case '3':
          encryptedString += 'b';
          break;
        case '4':
          encryptedString += 'e';
          break;
        case '5':
          encryptedString += 'v';
          break;
        case '6':
          encryptedString += '9';
          break;
        case '7':
          encryptedString += 'z';
          break;
        case '8':
          encryptedString += 'f';
          break;
        case '9':
          encryptedString += 'c';
          break;
        case '+':
          encryptedString += 'w';
          break;
        case '/':
          encryptedString += '3';
          break;
        case '=':
          encryptedString += '=';
          break;
      }

    }
    return encryptedString;
  }

  // decrypts body before processing the data
  private decryptBody(body) {
    let decryptedString: string = '';
    for (var i = 0; i < body.length; i++) {
      switch (body[i]) {
        case 'A':
          decryptedString += 'H';
          break;
        case 'B':
          decryptedString += 'V';
          break;
        case 'C':
          decryptedString += 'g';
          break;
        case 'D':
          decryptedString += 'a';
          break;
        case 'E':
          decryptedString += 'W';
          break;
        case 'F':
          decryptedString += 'd';
          break;
        case 'G':
          decryptedString += 'j';
          break;
        case 'H':
          decryptedString += 'Z';
          break;
        case 'I':
          decryptedString += 'm';
          break;
        case 'J':
          decryptedString += 'r';
          break;
        case 'K':
          decryptedString += 'o';
          break;
        case 'L':
          decryptedString += 'X';
          break;
        case 'M':
          decryptedString += 't';
          break;
        case 'N':
          decryptedString += 'v';
          break;
        case 'O':
          decryptedString += 'l';
          break;
        case 'P':
          decryptedString += 'x';
          break;
        case 'Q':
          decryptedString += 'b';
          break;
        case 'R':
          decryptedString += 'J';
          break;
        case 'S':
          decryptedString += 'i';
          break;
        case 'T':
          decryptedString += 's';
          break;
        case 'U':
          decryptedString += 'z';
          break;
        case 'V':
          decryptedString += 'G';
          break;
        case 'W':
          decryptedString += 'u';
          break;
        case 'X':
          decryptedString += 'y';
          break;
        case 'Y':
          decryptedString += '0';
          break;
        case 'Z':
          decryptedString += 'C';
          break;
        case 'a':
          decryptedString += 'h';
          break;
        case 'b':
          decryptedString += '3';
          break;
        case 'c':
          decryptedString += '9';
          break;
        case 'd':
          decryptedString += 'K';
          break;
        case 'e':
          decryptedString += '4';
          break;
        case 'f':
          decryptedString += '8';
          break;
        case 'g':
          decryptedString += '1';
          break;
        case 'h':
          decryptedString += 'S';
          break;
        case 'i':
          decryptedString += 'L';
          break;
        case 'j':
          decryptedString += 'D';
          break;
        case 'k':
          decryptedString += 'P';
          break;
        case 'l':
          decryptedString += 'A';
          break;
        case 'm':
          decryptedString += 'e';
          break;
        case 'n':
          decryptedString += 'T';
          break;
        case 'o':
          decryptedString += 'I';
          break;
        case 'p':
          decryptedString += 'F';
          break;
        case 'q':
          decryptedString += 'B';
          break;
        case 'r':
          decryptedString += 'M';
          break;
        case 's':
          decryptedString += '2';
          break;
        case 't':
          decryptedString += 'Y';
          break;
        case 'u':
          decryptedString += 'c';
          break;
        case 'v':
          decryptedString += '5';
          break;
        case 'w':
          decryptedString += '+';
          break;
        case 'x':
          decryptedString += 'O';
          break;
        case 'y':
          decryptedString += 'U';
          break;
        case 'z':
          decryptedString += '7';
          break;
        case '0':
          decryptedString += 'E';
          break;
        case '1':
          decryptedString += 'R';
          break;
        case '2':
          decryptedString += 'q';
          break;
        case '3':
          decryptedString += '/';
          break;
        case '4':
          decryptedString += 'n';
          break;
        case '5':
          decryptedString += 'f';
          break;
        case '6':
          decryptedString += 'N';
          break;
        case '7':
          decryptedString += 'Q';
          break;
        case '8':
          decryptedString += 'k';
          break;
        case '9':
          decryptedString += '6';
          break;
        case '+':
          decryptedString += 'p';
          break;
        case '/':
          decryptedString += 'w';
          break;
        case '=':
          decryptedString += '=';
          break;
      }
    }
    const json = atob(decryptedString);
    return json;
  }
}
