import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { BannerModel } from "../pages/models/banner.model";
import {
  CustomerDetailsModel,
  UserInfoModel,
} from "../pages/models/user.model";
import { CrudService } from "./crud.service";
import { KYCInfoModel } from "../pages/models/AirteltigoMoney";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class HelpService {
  private firstTipInfo = new BehaviorSubject(true); // creates the new subject that emits when user selects an option
  public firstTip = this.firstTipInfo.asObservable(); // creates an observable that listens to the first tip subject

  private buyProcess = new BehaviorSubject(null); // emits data every time user proceeds to next step in buy sim page
  public buyProcessData = this.buyProcess.asObservable(); // creates a new observable that subscribes to changes in buy process

  private profileImage = new BehaviorSubject(null); // emits data when profile image is changed
  public imageInfo = this.profileImage.asObservable(); // creates a new observable that subscribes to changes in profile image

  private quickActionsData = new BehaviorSubject(false); // emits when quick action is added or removed
  public quickActions = this.quickActionsData.asObservable(); // subscribes to quick actions data

  private bannerData = new BehaviorSubject(null); // emits list of banner images
  public bannerImages = this.bannerData.asObservable(); // listens to banner data subject, subscribes to the latest value

  private rbtData = new BehaviorSubject(false); // emits when user registers to RBT platform
  public rbtInfo = this.rbtData.asObservable(); // creates a new observable that subscribes to changes in rbtData subject

  private moneySectionSubject = new BehaviorSubject(false); // emits true when user routes to money section
  public moneysection = this.moneySectionSubject.asObservable(); // listens to money section subject

  constructor(private crud: CrudService) {}

  // emits data to hide first tip
  public hideFirstTip(value: boolean): void {
    this.firstTipInfo.next(value);
  }

  // emits current step data
  public emitCurrentStepData(data: any): void {
    this.buyProcess.next(data);
  }

  // emits new profile image
  public emitProfileImage(imageName: string): void {
    this.profileImage.next(imageName);
  }

  // emits when quick action is added or updated
  public quickActionStatus(status: boolean): void {
    this.quickActionsData.next(status);
  }

  // emits banner images
  public emitBannerImages(images: Array<BannerModel>): void {
    this.bannerData.next(images);
  }

  // emits rbt status
  public emitRBTStatus(status: boolean): void {
    this.rbtData.next(status);
  }

  // emits money section status
  public emitMoneySectionStatus(status: boolean): void {
    this.moneySectionSubject.next(status);
  }

  // get's user's balance information
  public getUserAccountBalance(): Observable<any> {
    return this.crud.getData("user/sim/detail", "auth");
  }

  // get's user's mfs balance
  public getUserMFSBalance(): Observable<any> {
    return this.crud.getData("mfs/check/balance", "mfs");
  }

  // get's user's credit limit
  public getPostPaidUserCreditLimit(): Observable<any> {
    return this.crud.getData("get/credit-limit", "auth");
  }

  // encodes mfs information to base 64 and returns base 64 string
  public encodeMfsData(value: string): string {
    const encodedString = btoa(value);
    const secondLevelEcodedValue =
      environment.firstEncodeString +
      encodedString +
      environment.secondEncodeString;
    const finalEncodedString = btoa(secondLevelEcodedValue);
    return finalEncodedString;
  }

  // get default customer information values
  public getDefaultCustomerInfo(): CustomerDetailsModel {
    return {
      hlrstatus: null,
      lastName: null,
      cbsstatus: null,
      updateDate: null,
      kycStatus: null,
      gender: null,
      city: null,
      docNumber: null,
      channel: null,
      imsi: null,
      iccid: null,
      registrationCount: null,
      workItemTaskName: null,
      customerId: null,
      action: null,
      msisdn: null,
      docstatus: null,
      createDate: null,
      address: null,
      docType: null,
      dateOfBirth: null,
      userName: null,
      transactionId: null,
      simType: null,
      firstName: null,
      accountId: null,
      doAudit: null,
      nationality: null,
      mfsStatus: null,
      spokenLanguage: null,
      fullname: null,
      region: null,
      status: null,
      email: null,
    };
  }
}
