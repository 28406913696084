export const environment = {
  production: true,
  SOCKET_URL: "http://10.81.0.81:3080/",
  SONG_PLAY_URL: "http://10.176.122.207:8091/webcont",
  AUTH_URL: "http://10.81.0.81:3000/",
  MFS_RBT_URL: "http://10.81.0.81:3020/",
  USER_URL: "http://10.81.0.81:2000/",
  s3Bucket: "assets/Loader/",
  headerEnrichmentUrl: "http://10.81.0.81:2000",
  uniqueKey: "eGFudGhpdW1BcHByb2FjaGluZ1RyYW5xdWlsaXR5QmFzZQ==\n",
  firstEncodeString: "qwiojkl/nm",
  secondEncodeString: "nmbvxc",
  aesEncryptionKey: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
  aesEncryptionIV: [
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36,
  ],
  chatRoom: "abc123",
};
