import {Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {CrudService} from './services/crud.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Network} from '@ngx-pwa/offline';
import {SocketService} from './services/socket.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../environments/environment';
import {BrowserDetailsModel} from './pages/models/user.model';
import {Router} from '@angular/router';
import {HelpService} from './services/help.service';

declare let $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public title = 'airtelwebapp';
  @ViewChild('internetDisconnectModal') public internetDisconnectModal;        // contains reference to internet disconnected modal element
  private modalRef: NgbModalRef;        // contains internet disconnected modal reference
  private userMsisdn: string = null;      // contains user's msisdn from headers
  private readonly deviceDetail: BrowserDetailsModel;      // contains details of browser in which this application is executed.
  constructor(private crud: CrudService, private modalService: NgbModal, private network: Network, private socket: SocketService, private http: HttpClient, private router: Router) {
    if (sessionStorage.getItem('token')) {
      this.crud.setToken = atob(sessionStorage.getItem('token'));
      this.socket.connect();
    }
    if (environment.uniqueKey && !sessionStorage.getItem('token')) {
      this.deviceDetail = this.getBrowserDetails();
      this.checkEnrichedHeaders();
    }
    // // disables context menu
    // $('body').on('contextmenu', function (e) {
    //   return false;
    // });
    //
    // // disables cut, copy and paste options
    // $('body').bind('cut copy paste', function (e) {
    //   e.preventDefault();
    // });
  }

  ngOnInit(): void {
    // this.monitorsInternetStatus();
    this.network.onlineChanges.subscribe(status => {
      if (status) {
        if (this.modalRef) {
          this.modalRef.close();
        }
      } else {
        this.modalRef = this.modalService.open(this.internetDisconnectModal, {backdrop: false, keyboard: false});
      }
    });
  }

  // gets browser details
  private getBrowserDetails(): BrowserDetailsModel {
    return {
      appCodeName: navigator.appCodeName,
      appName: navigator.appName,
      appVersion: navigator.appVersion,
      userAgent: navigator.userAgent,
      platform: navigator.platform
    };
  }

  // check's enriched for user's msisdn
  private checkEnrichedHeaders(): void {
    this.http.get(environment.headerEnrichmentUrl).subscribe((res: any) => {
      if (res.ress['user-msisdn']) {
        let mobileNumber = String(res.ress['user-msisdn']).split('=')[1].slice(3);
        mobileNumber = '0' + mobileNumber;
        const body = {
          mobileNumber,
          loginBy: 'web',
          deviceDetail: this.deviceDetail,
          isOtherNumber: false,
          secretLoginData: 'eGFudGhpdW1BcHByb2FjaGluZ1RyYW5xdWlsaXR5QmFzZQ==\n'
        };
        this.crud.saveData('user/login/header', body, 'auth').subscribe((res: any) => {
          if (res.response_code === 200) {
            const encodedToken = btoa(res.response_data.data.token);
            const encodedData = btoa(JSON.stringify(res.response_data.data.user));
            this.crud.setToken = res.response_data.data.token;
            sessionStorage.setItem('userData', encodedData);
            sessionStorage.setItem('token', encodedToken);
            sessionStorage.setItem('id', res.response_data.data.user._id);
            this.router.navigate(['airteltigo/home']);
          }
        }, error => {
          console.clear();
        });
      }
    });
  }
}
