import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {BankToWalletModel, SendMoneyModel, TransactionResponseModel, WithdrawModel} from '../../models/AirteltigoMoney';
import {CrudService} from '../../../services/crud.service';
import {HelpService} from '../../../services/help.service';

@Component({
  selector: 'app-mfa-payment-confirmation',
  templateUrl: './mfa-payment-confirmation.component.html',
  styleUrls: ['./mfa-payment-confirmation.component.scss']
})
export class MfaPaymentConfirmationComponent implements OnInit, OnChanges, AfterViewInit {
  @Output() private closePayment = new EventEmitter<boolean>(false);      //   close's payment confirmation window
  @Output() private transactionSuccess = new EventEmitter<TransactionResponseModel>();      // emits transaction response object
  @Input() public mfsData: SendMoneyModel | WithdrawModel | BankToWalletModel;       // contains information about mfs data
  @Input() public selectedTab: string = null;     // contains name of the selected tab
  @Input() public isAirtelTigoUser: boolean = false;      // set to true if the receiver is an airteltigo user
  public showOTPModal: boolean = false;     // shows otp modal when true
  public isLoading: boolean = false;      // disables button when true
  private defaultErrorRes = {
    response_code: 400,
    response_data: {
      message: 'Something went wrong, Please try again'
    }
  };        // contains default error response
  private buttonData = null;     // contains button reference
  @Input() transactionFee: number = 0;     // contains transaction fee
  public transactionTotal: number = 0;      // contains total amount charged by the user
  @Input() bankWithDrawType: number = null;     // contains information about bank withdraw

  constructor(private crud: CrudService, private help: HelpService) {

  }

  ngOnInit(): void {

  }

  ngOnChanges(): void {
    this.transactionTotal = Number(this.mfsData.amount) + this.transactionFee;
  }

  ngAfterViewInit(): void {
    this.buttonData = document.getElementById('add-to-fav-btn');
    if (this.buttonData) {
      this.buttonData.addEventListener('click', () => {
        const recipientName = document.getElementById('recipient-name').innerText;
        let body = {
          favName: recipientName,
          favNumber: this.mfsData['mobileNumber']
        };
        this.addToFavouriteList(body);
      });
    }
  }

  // adds receiver to senders favourite list
  private addToFavouriteList(body): void {
    this.crud.saveData('favouritecontact', body, 'auth').subscribe((res: any) => {
      if (res.response_code === 200) {
        this.buttonData.style.display = 'none';
      }
    }, error => {

    });
  }

  // closes payment confirmation window
  public closePaymentConfirmation(): void {
    this.closePayment.next(true);
  }

  // opens OTP verification modal
  public openOTPVerificationModal(): void {
    this.showOTPModal = true;
  }

  // checks the type of transaction and makes the corresponding api calls
  public checkTransactionType(mfsPin: string): void {
    this.showOTPModal = false;
    switch (this.selectedTab) {
      case 'Send':
        this.sendMoneyToUser(mfsPin);
        break;
      case 'Cash':
        this.checkWithdrawTransactionType(mfsPin);
        break;
      case 'Bank':
        this.checkBankWithDrawType(mfsPin);
    }
  }

  // checks bank withdraw type
  private checkBankWithDrawType(mfsPIN: string): void {
    this.mfsData['pin'] = this.help.encodeMfsData(mfsPIN);
    switch (this.bankWithDrawType) {
      case 3:
        this.withDrawFromWalletToBank();
        break;
      case 4:
        this.withDrawFromBankToWallet();
        break;
    }
  }

  // returns payment heading
  public getPaymentHeading(): string {
    switch (this.selectedTab) {
      case 'Send':
        return 'Confirm Money Transfer';
      case 'Cash':
        return 'Confirm Withdrawal';
      case 'Bank':
        return 'Confirm Bank Transfer';
    }
  }

  // sends request to transfer money from wallet to bank
  private withDrawFromWalletToBank(): void {
    this.isLoading = true;
    this.crud.saveData('mfs/wallet/to/bank', this.mfsData, 'mfs').subscribe((res: any) => {
      this.isLoading = false;
      this.handleTransactionStatus(res);
    }, error => {
      this.isLoading = false;
      this.handleTransactionStatus(this.defaultErrorRes);
    });
  }

  // sends request to transfer money from bank to wallet
  private withDrawFromBankToWallet(): void {
    this.isLoading = true;
    this.crud.saveData('mfs/bank/to/wallet', this.mfsData, 'mfs').subscribe((res: any) => {
      this.isLoading = false;
      this.handleTransactionStatus(res);
    }, error => {
      this.isLoading = false;
      this.handleTransactionStatus(this.defaultErrorRes);
    });
  }

  // checks withdraw transaction type
  private checkWithdrawTransactionType(mfsPin): void {
    this.mfsData['mpin'] = this.help.encodeMfsData(mfsPin);
    const withdrawData = this.mfsData as WithdrawModel;
    switch (withdrawData.withdrawType) {
      case 1:
        this.withDrawMoneyFromAgent(withdrawData);
        break;
      case 2:
        this.withDrawAmountFromATM();
        break;
    }
  }

  // sends request to withdraw amount from airteltigo agent
  private withDrawMoneyFromAgent(withdrawData: WithdrawModel): void {
    if (!withdrawData.mobileNumber.startsWith('0')) {
      withdrawData.mobileNumber = '0' + withdrawData.mobileNumber;
    }
    this.isLoading = true;
    this.crud.saveData('mfs/cashout', withdrawData, 'mfs').subscribe((res: any) => {
      this.isLoading = false;
      this.handleTransactionStatus(res);
    }, error => {
      this.isLoading = false;
      this.handleTransactionStatus(this.defaultErrorRes);
    });
  }

  // sends request to withdraw amount from ATM
  private withDrawAmountFromATM(): void {
    delete this.mfsData['mobileNumber'];
    this.isLoading = true;
    this.crud.saveData('mfs/withdraw/atm', this.mfsData, 'mfs').subscribe((res: any) => {
      this.handleTransactionStatus(res);
    }, error => {
      this.isLoading = false;
      this.handleTransactionStatus(this.defaultErrorRes);
    });
  }

  // sends request to send money to an user
  private sendMoneyToUser(mPin: string): void {
    this.mfsData['mpin'] = this.help.encodeMfsData(mPin);
    if (this.isAirtelTigoUser) {
      this.sendMoneyToAirtelTigoUser();
    } else {
      this.sendMoneyToNonAirtelTigoUser();
    }
  }

  // sends money to other airteltigo user
  private sendMoneyToAirtelTigoUser() {
    this.isLoading = true;
    this.crud.saveData('mfs/transfer/am/other/airtel/user', this.mfsData, 'mfs').subscribe((res: any) => {
      this.isLoading = false;
      this.handleTransactionStatus(res);
    }, error => {
      this.isLoading = false;
      this.handleTransactionStatus(this.defaultErrorRes);
    });
  }

  // sends request to send money to non airteltigo user
  private sendMoneyToNonAirtelTigoUser(): void {
    delete this.mfsData['senderName'];
    delete this.mfsData['receiverName'];
    this.isLoading = true;
    this.crud.saveData('mfs/transfer/am/other/network/user', this.mfsData, 'mfs').subscribe((res: any) => {
      this.isLoading = true;
      this.handleTransactionStatus(res);
    }, error => {
      this.isLoading = false;
      this.handleTransactionStatus(this.defaultErrorRes);
    });
  }

  // handles transaction status
  private handleTransactionStatus(res) {
    if (res.response_code !== 200) {
      const errorBody: TransactionResponseModel = {
        status: false,
        message: res.response_data.message
      };
      this.transactionSuccess.next(errorBody);
      return;
    }
    const successBody: TransactionResponseModel = {
      status: true,
      message: res.response_data.message
    };
    this.transactionSuccess.next(successBody);
  }
}
